/* eslint-disable no-unused-vars */
import {
  EllipsisOutlined,
  LeftOutlined,
  StarFilled,
  StarOutlined
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  Col,
  Divider,
  Form,
  Input,
  message,
  Popover,
  Row,
  Select,
  TimePicker
} from 'antd';
import 'antd/dist/antd.css';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useForm } from 'antd/lib/form/Form';
import { get } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import {
  findLocationInfoByAddress,
  formValidatorRules
} from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonInput from '../../../components/CommonInput';
import CommonTable from '../../../components/CommonTable';
import CommonTag from '../../../components/CommonTag';
import Map from '../../../components/googleMap';
import ImageUpload from '../../../components/imageUpload/ImageUpload';
import SelectComponent from '../../../components/selectComponent/SelectComponent';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_ALL_CITIES } from '../../city/graphql/Queries';
import '../eatery.less';
import { SORT_EATERIES } from '../graphql/Queries';
import AddDish from './AddDish';
import DeleteDish from './DeleteDish';
import EditDish from './EditDish';

const { required, phoneNumber } = formValidatorRules;

function EateryForm({ mutation, eateryData, id, mutating }) {
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const suggestionId = params?.get('suggestionId');
  const history = useHistory();
  const [form] = useForm();
  const [sameWeekendTime, setSameWeekendTime] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const { data, loading, error } = useQuery(GET_ALL_CITIES);
  const { TextArea } = Input;
  const [formChanged, setFormChanged] = useState(false);
  const [sortBy, setSortBy] = useState('updatedAtDesc');

  const [address, setAddress] = useState();
  const [locationInfo, setLocationInfo] = useState({
    address: '',
    markerInfo: {
      latitude: null,
      longitude: null
    }
  });

  // for filter by type
  const [dishTypes, setDishTypes] = useState([]);
  const [dishDiets, setDishDiets] = useState([]);
  const [filterInfo, setFilterInfo] = useState('');
  const { data: singleEatery } = useQuery(SORT_EATERIES, {
    skip: !id,
    variables: {
      id,
      sortBy
    },
    fetchPolicy: 'network-only'
  });
  const menuData = singleEatery?.singleEatery;
  useEffect(() => {
    if (pathname?.includes('edit')) {
      setAddress(eateryData?.addressLine);
    }
    eateryData?.menu?.forEach((eatery) => {
      if (!dishTypes?.includes(eatery?.dishType?.type)) {
        setDishTypes([...dishTypes, eatery?.dishType?.type]);
      }
    });
    eateryData?.menu?.forEach((eatery) => {
      eatery?.dietaryType?.forEach((dietaryTypeObj) => {
        if (!dishDiets?.includes(dietaryTypeObj?.type)) {
          setDishDiets([...dishDiets, dietaryTypeObj?.type]);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dishTypes, dishDiets, eateryData?.menu]);

  // eslint-disable-next-line no-shadow
  const handleSelect = async (address) => {
    const locationInformation = await findLocationInfoByAddress(address);
    setAddress(address);
    setLocationInfo({ ...locationInformation });
    form?.setFieldsValue({ ...locationInformation });
  };

  const onError = (status, clearSuggestions) => {
    message?.error('Google Maps API returned error with status: ', status);
    clearSuggestions();
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  const columns = [
    {
      title: 'Dish name',
      dataIndex: 'dishName',
      key: '1',
      sorter: true,
      ellipsis: true
    },
    {
      title: 'Type',
      dataIndex: ['dishType', 'type'],
      key: '2',
      align: 'center',
      filters:
        dishTypes?.length &&
        dishTypes?.sort()?.map((city) => {
          return { text: city, value: city };
        }),
      filteredValue: get(filterInfo, 2) || null,
      onFilter: (value, record) => record?.dishType?.type?.includes(value)
    },
    {
      title: 'Diet',
      dataIndex: ['dietaryType'],
      key: '3',
      align: 'center',
      render: (dietaryTypeObj) => {
        return dietaryTypeObj?.map((dietary) => <div>{dietary?.type}</div>);
      },
      filters:
        dishDiets?.length &&
        dishDiets?.sort()?.map((city) => {
          return { text: city, value: city };
        }),
      filteredValue: get(filterInfo, 3) || null,
      onFilter: (value, record) =>
        record?.dietaryType?.some((dietary) => dietary?.type?.includes(value))
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: '4',
      align: 'center',
      sorter: true
    },
    {
      title: 'Suggested by',
      dataIndex: 'suggestedBy',
      key: '5',
      align: 'center',
      sorter: true
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: '6',
      align: 'center',
      render: (featured) => {
        if (featured) {
          return <StarFilled />;
        }
        return <StarOutlined />;
      }
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '7',
      align: 'center',
      render: (value) => {
        const { id: dishId } = value;
        return (
          <Row justify="start">
            <Popover
              content={
                <>
                  <EditDish
                    id={dishId}
                    cityId={eateryData?.city?.id}
                    actionName="editDish"
                  />
                  <DeleteDish id={dishId} actionName="deleteDish" />
                </>
              }
            >
              <CommonButton
                type="text"
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              >
                <EllipsisOutlined />
              </CommonButton>
            </Popover>
          </Row>
        );
      }
    }
  ];

  const onFinish = (formData) => {
    const {
      name,
      contactNo,
      selectCity,
      eateryTiming,
      zomatoAvailable,
      swiggyAvailable,
      description
    } = formData;
    const {
      isSameAsWeekday,
      weekdayOpen,
      weekdayClose,
      weekendOpen,
      weekendClose
    } = eateryTiming;
    const { id: cityId } = selectCity;
    mutation({
      variables: {
        id: id || '',
        name,
        contactNo,
        cityId: parseInt(cityId, 10),
        isSameAsWeekday: isSameAsWeekday || false,
        weekdayOpen: moment(weekdayOpen)?.format('hh:mm A'),
        weekdayClose: moment(weekdayClose)?.format('hh:mm A'),
        weekendOpen: moment(weekendOpen)?.format('hh:mm A'),
        weekendClose: moment(weekendClose)?.format('hh:mm A'),
        imageUrl: imageUrl,
        location: {
          longitude: locationInfo?.markerInfo?.longitude,
          latitude: locationInfo?.markerInfo?.latitude
        },
        zomatoAvailable:
          zomatoAvailable?.trim()?.length > 0 ? zomatoAvailable : null,
        swiggyAvailable:
          swiggyAvailable?.trim()?.length > 0 ? swiggyAvailable : null,
        description,
        address: formData?.address || address
      }
    })
      .then(() => {
        if (suggestionId) {
          history?.push({
            pathname: `${ROUTES?.SUGGESTIONS}/${suggestionId}`,
            state: { visible: true }
          });
        } else {
          history?.push(ROUTES?.EATERY);
        }
      })
      ?.catch(() => {});
  };

  const handleFieldsChange = () => {
    if (sameWeekendTime) {
      const weekendOpen = form?.getFieldValue(['eateryTiming', 'weekdayOpen']);
      const weekendClose = form?.getFieldValue([
        'eateryTiming',
        'weekdayClose'
      ]);

      form?.setFieldsValue({
        eateryTiming: {
          weekendOpen,
          weekendClose
        }
      });
    }
  };

  const handleTableChange = (_, filters, sorter) => {
    setFilterInfo(filters);
    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;

      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('dishNameAsc');
              break;
            case '4':
              setSortBy('priceAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('dishNameDesc');
              break;
            case '4':
              setSortBy('priceDesc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        default:
          setSortBy('updatedAtDesc');
      }
    }
  };

  return (
    <div>
      <Prompt
        message="You have unsaved changes! Are you sure you want to leave this page"
        when={formChanged}
      />
      <CommonCard
        className="mb-32"
        title={
          <Row justify="space-between" align="middle">
            <Col>
              <CommonButton
                onClick={() => {
                  history?.goBack();
                }}
                type="default"
              >
                <LeftOutlined />
              </CommonButton>
            </Col>
            <Col>
              <h3 className="mb-0">Basic Details</h3>
            </Col>
            <Col>
              <Row gutter={10}>
                <Col>
                  <CommonButton
                    type="default"
                    onClick={() => {
                      history?.goBack();
                    }}
                  >
                    {pathname?.includes('edit') ? 'Discard' : 'Cancel'}
                  </CommonButton>
                </Col>
                <Col>
                  <Form onFinish={onFinish} form={form}>
                    <CommonButton
                      type="primary"
                      htmlType="submit"
                      loading={mutating}
                      onClick={() => setFormChanged(false)}
                    >
                      {pathname?.includes('edit') ? 'Save' : 'Add'}
                    </CommonButton>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      >
        <Form
          requiredMark
          onValuesChange={() => setFormChanged(true)}
          onFieldsChange={handleFieldsChange}
          onFinish={onFinish}
          initialValues={
            eateryData && {
              ...eateryData,
              eateryTiming: {
                weekdayOpen: moment(eateryData?.eateryTiming?.weekdayOpen, [
                  'h:mm '
                ]),
                weekdayClose: moment(eateryData?.eateryTiming?.weekdayClose, [
                  'h:mm '
                ]),
                weekendOpen: moment(eateryData?.eateryTiming?.weekendOpen, [
                  'h:mm '
                ]),
                weekendClose: moment(eateryData?.eateryTiming?.weekendClose, [
                  'h:mm '
                ])
              },
              selectCity: eateryData?.city,
              address: eateryData?.addressLine
            }
          }
          form={form}
          layout="vertical"
        >
          <Row gutter={24} justify="space-between">
            <Col span={14}>
              <Form.Item name="name" label="Name" rules={[required]}>
                <CommonInput placeHolder="Name" />
              </Form.Item>
              <Form.Item
                name="contactNo"
                label="Contact Number"
                rules={[required, phoneNumber]}
              >
                <CommonInput placeHolder="Contact Number" />
              </Form.Item>
              <Form.Item
                name={['selectCity', 'id']}
                rules={[required]}
                label="City"
              >
                <SelectComponent placeHolder="Select City">
                  {data?.getAllCities?.cities?.map((city) => {
                    return (
                      <Select.Option value={city?.id} key={city?.id}>
                        {city?.name}
                      </Select.Option>
                    );
                  })}
                </SelectComponent>
              </Form.Item>
              <Form.Item name="address" label="Address" rules={[required]}>
                <div>
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                    onError={onError}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading: locationInfoLoading
                    }) => (
                      <>
                        <Input
                          {...getInputProps({
                            placeholder: 'Type address'
                          })}
                        />

                        <div>
                          {locationInfoLoading && <Loader />}

                          {suggestions?.map((suggestion) => {
                            const className = suggestion?.active
                              ? 'suggestion-active'
                              : 'suggestion';
                            return (
                              <>
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className
                                  })}
                                  key={suggestion?.index}
                                >
                                  {suggestion?.description}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </PlacesAutocomplete>
                </div>
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[required]}
              >
                <Input.TextArea placeholder="Description" autoSize />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="WeekDays"
                    name={['eateryTiming', 'weekdayOpen']}
                    rules={[required]}
                  >
                    <TimePicker className="timepicker-auto" format="hh:mm a" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="to"
                    name={['eateryTiming', 'weekdayClose']}
                    rules={[required]}
                  >
                    <TimePicker className="timepicker-auto" format="hh:mm a" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name={['eateryTiming', 'isSameAsWeekday']}>
                <Checkbox
                  valuePropName="checked"
                  onChange={(e) => {
                    setSameWeekendTime(e?.target?.checked);
                  }}
                >
                  Same as weekdays
                </Checkbox>
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Weekends"
                    name={['eateryTiming', 'weekendOpen']}
                    rules={[required]}
                  >
                    <TimePicker
                      className="timepicker-auto"
                      format="hh:mm a"
                      disabled={sameWeekendTime}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="to"
                    name={['eateryTiming', 'weekendClose']}
                    rules={[required]}
                  >
                    <TimePicker
                      className="timepicker-auto"
                      format="hh:mm a"
                      disabled={sameWeekendTime}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="mb-0"
                    label="Zomato Link"
                    name="zomatoAvailable"
                  >
                    <Input placeholder="Add Link" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="mb-0"
                    label="Swiggy Link"
                    name="swiggyAvailable"
                  >
                    <Input placeholder="Add Link" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Divider className="vertical-hr" type="vertical" />
            <Col span={9}>
              <Form.Item label="Image">
                <ImageUpload
                  setImageUrl={setImageUrl}
                  existingImageSrc={eateryData?.imageUrl}
                  imageKey="EATERY"
                />
              </Form.Item>
              <Form.Item label="Map">
                <Map
                  initialPosition={
                    locationInfo?.markerInfo?.latitude &&
                    locationInfo?.markerInfo?.longitude
                      ? locationInfo?.markerInfo
                      : eateryData?.location
                  }
                  setLocationInfo={setLocationInfo}
                  form={form}
                  setAddress={setAddress}
                />
              </Form.Item>
              <Form.Item label="Tags" className="mb-0">
                {pathname?.includes('edit') && (
                  <>
                    {eateryData?.famousFor?.map((dish) => {
                      return (
                        <Col key={dish?.id}>
                          <CommonTag label={dish?.dishName} />
                        </Col>
                      );
                    })}
                  </>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CommonCard>
      {pathname?.includes('edit') && (
        <CommonCard
          className="table-card"
          title={
            <Row justify="space-between" align="middle">
              <Col>
                <h3 className="mb-0">Menu</h3>
              </Col>
              <Col>
                <AddDish cityId={eateryData?.city?.id} />
              </Col>
            </Row>
          }
        >
          <>
            <CommonTable
              rowKey={(e) => e?.id}
              columns={columns}
              data={eateryData?.menu}
              tableRowIsClickable={false}
              onChange={handleTableChange}
            />
          </>
        </CommonCard>
      )}
    </div>
  );
}
export default EateryForm;
