import { gql } from '@apollo/client';

const CONTACT_US_LIST = gql`
  query ContactUsList($filter: ContactUsFilter!) {
    contactUsList(filter: $filter) {
      count
      userList {
        id
        firstName
        lastName
        contactNumber
        message
        email
      }
    }
  }
`;

export default CONTACT_US_LIST;
