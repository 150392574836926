import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddCategory from './components/AddCategory';
import CategoryEdit from './components/CategoryEdit';
import CategoryView from './components/CategoryView';

function Dishcategories() {
  return (
    <Switch>
      <Route path={ROUTES.CATEGORIES_EDIT} component={CategoryEdit} />
      <Route path={ROUTES.CATEGORIES_ADD} component={AddCategory} />
      <Route path={ROUTES.CATEGORIES_VIEW} component={CategoryView} />
    </Switch>
  );
}

export default Dishcategories;
