import { useMutation } from '@apollo/client';
import React from 'react';
import { CREATE_EATERY } from '../graphql/Mutations';
import { GET_ALL_EATERIES } from '../graphql/Queries';
import EateryForm from './EateryForm';

function AddEatery() {
  const [createEatery] = useMutation(CREATE_EATERY, {
    refetchQueries: [
      { query: GET_ALL_EATERIES, variables: { sortBy: 'updatedAtDesc' } }
    ]
  });
  return (
    <div>
      <EateryForm mutation={createEatery} />
    </div>
  );
}

export default AddEatery;
