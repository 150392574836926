import { useMutation } from '@apollo/client';
import { Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { capitalizeWord, formValidatorRules } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonModal from '../../../components/CommonModal';
import { ADD_NEW_CITY } from '../graphql/Mutations';
import { GET_ALL_CITIES } from '../graphql/Queries';

const { required } = formValidatorRules;

function AddCity() {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const [form] = useForm();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [addNewCity, { loading: mutating }] = useMutation(ADD_NEW_CITY, {
    refetchQueries: [{ query: GET_ALL_CITIES }]
  });

  const handleOk = () => {
    form?.submit();
  };
  const handleCancel = () => {
    form?.resetFields();
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };
  const onFinish = (e) => {
    const { name = '', description = '' } = e;
    addNewCity({
      variables: { name: capitalizeWord(name), description }
    })
      ?.then(() => {
        setModalVisible(false);
        history?.push(`${ROUTES?.CITY}`);
      })
      ?.catch((error) => {
        return error;
      });
  };
  return (
    <div>
      {user?.role === 'SUPER_ADMIN' && (
        <CommonButton className="mb-12" type="default" onClick={showModal}>
          Add City
        </CommonButton>
      )}
      <CommonModal
        okText="Add"
        title="Add new city"
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={modalVisible}
        okButtonProps={{ loading: mutating }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item label="City Name" name="name" rules={[required]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="City Description"
            name="description"
            rules={[required]}
            className="mb-0"
          >
            <Input />
          </Form.Item>
        </Form>
      </CommonModal>
    </div>
  );
}

export default AddCity;
