import { useMutation } from '@apollo/client';
import { Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CITY_STATUS, ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonModal from '../../../components/CommonModal';
import { UPDATE_CITY_STATUS } from '../graphql/Mutations';
import { GET_ALL_CITIES } from '../graphql/Queries';

const { required } = formValidatorRules;

function EditCity({ id, status: currentStatus }) {
  const [form] = useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const history = useHistory();
  const [updateCityStatus, { loading: mutating }] = useMutation(
    UPDATE_CITY_STATUS,
    {
      refetchQueries: [
        {
          query: GET_ALL_CITIES,
          fetchPolicy: 'cache-and-network',
          variables: { status: 'DRAFT' }
        }
      ]
    }
  );
  const handleOk = () => {
    form?.submit();
  };
  const handleCancel = () => {
    form?.resetFields();
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };
  const onFinish = (e) => {
    const { status = '' } = e;
    if (status) {
      updateCityStatus({ variables: { id, status } })
        ?.then(() => {
          setModalVisible(false);
          history?.push(`${ROUTES?.CITY}`);
        })
        ?.catch(() => {});
    }
  };

  return (
    <div>
      <CommonButton onClick={showModal} type="primary">
        Edit
      </CommonButton>
      <CommonModal
        okText="Assign"
        title="Edit city"
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={modalVisible}
        okButtonProps={{ loading: mutating }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            label="Status"
            name="status"
            className="mb-0"
            rules={[required]}
            initialValue={
              currentStatus === CITY_STATUS?.DRAFT
                ? CITY_STATUS?.LIVE
                : CITY_STATUS?.DRAFT
            }
          >
            <Select>
              {currentStatus === CITY_STATUS?.DRAFT && (
                <Select.Option value={CITY_STATUS?.LIVE}>Live</Select.Option>
              )}
              {currentStatus === CITY_STATUS?.LIVE && (
                <Select.Option value={CITY_STATUS?.DRAFT}>Draft</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Form>
      </CommonModal>
    </div>
  );
}

export default EditCity;
