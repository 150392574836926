import { message } from 'antd';
import moment from 'moment';
import Geocode from 'react-geocode';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import api from './api';
import {
  API_KEY,
  defaultDateFormat,
  DEV_WEB_URL,
  PROD_WEB_URL,
  REGEX
} from './constants';

Geocode.setApiKey(API_KEY.GOOGLE_API_KEY);
Geocode.enableDebug();

export const refreshGrid = () => {
  // eslint-disable-next-line no-undef
  window.dispatchEvent(new Event('refresh-grid'));
};

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`
) => {
  if (datetime && moment && format) {
    return moment(datetime).format(format);
  }
  return datetime;
};
export const formatTime = (time, format = `hh:mm A`) => {
  if (time && moment && format) {
    return moment(time, format).format(format);
  }
  return time;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'This field is required'
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Should be a valid Number');
      }
      return Promise.resolve();
    }
  }),
  phoneNumber: () => ({
    validator(rule, value) {
      if (value) value.split(' ').join('');
      const numberPattern = REGEX.PHONE;
      if (value && !numberPattern.test(value)) {
        return Promise.reject(new Error('should be a valid phone number'));
      }
      return Promise.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice).toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formItemProps = { normalize: (value) => value.trim() };

export const uploadImageToS3 = async (image, putUrl) => {
  const headers = {
    'Content-Type': image.type,
    'x-amz-acl': 'public-read'
  };
  try {
    await api(putUrl, {
      method: 'PUT',
      data: image,
      headers
    });
  } catch (error) {
    message.error(error.message);
  }
};

// google map  API related utility functions

export const locationInfoFinder = (addressArray, types) => {
  let result;
  for (let i = 0; i < addressArray?.length; i += 1) {
    if (addressArray[i]?.types[0] === types) {
      result = addressArray[i]?.long_name;
      return result;
    }
  }
};

export const getArea = (addressArray) => {
  let area = '';
  for (let i = 0; i < addressArray.length; i += 1) {
    for (let j = 0; j < addressArray.length; j += 1) {
      if (
        addressArray[i]?.types.includes('sublocality_level_1') ||
        addressArray[i]?.types[0] === 'locality'
      ) {
        area = addressArray[i].long_name;
        return area;
      }
    }
  }
};

export const findLocationInfoByAddress = async (address) => {
  const results = await geocodeByAddress(address);
  const latLng = await getLatLng(results[0]);

  const locationInformation = {
    markerInfo: {
      latitude: latLng.lat,
      longitude: latLng.lng
    },
    address
  };
  return locationInformation;
};

export const findLocationInfoByLatLng = async (lat, lng) => {
  const response = await Geocode.fromLatLng(lat, lng);
  const address = response?.results[0]?.formatted_address;

  const locationInformation = {
    markerInfo: {
      latitude: lat,
      longitude: lng
    },
    address
  };
  return locationInformation;
};
export const capitalizeWord = (word = '') => {
  return word?.length > 0 ? word[0].toUpperCase() + word.substring(1) : null;
};

export const btnClickHandler = (URL = '') => {
  switch (process?.env?.REACT_APP_ENV) {
    case 'localhost':
      return `http://localhost:3000/${URL}`;
    case 'production':
      return `${PROD_WEB_URL}${URL}`;
    case 'development':
      return `${DEV_WEB_URL}${URL}`;
    default:
      return `${PROD_WEB_URL}${URL}`;
  }
};
