import { gql } from '@apollo/client';

export const GET_REPORTED_REVIEWS_EATERIES = gql`
  query reportedReviewsAndEateries(
    $skip: Int
    $limit: Int
    $sortBy: reportedReviewSortBy
    $type: reportType
  ) {
    reportedReviewsAndEateries(
      filter: { sortBy: $sortBy, skip: $skip, limit: $limit, type: $type }
    ) {
      id
      type
      reason
      writtenBy
      writtenById
      reportedBy
      reportedById
      eateryName
      eateryId
      createdAt
    }
  }
`;

export const GET_SINGLE_REPORTED_REVIEW = gql`
  query($id: ID!) {
    singleReportedReview(id: $id) {
      id
      reason
      writtenBy
      reportedBy
      eateryName
      usersFollowerCount
      userLevel
      favouriteDish {
        id
        name
      }
      reviewCount
      suggestionCount
      reviewGiven
    }
  }
`;

export const GET_SINGLE_REPORTED_EATERY = gql`
  query($id: ID!) {
    singleReportedEatery(id: $id) {
      id
      reason
      reportedBy
      eateryName
      contactNo
      city {
        name
      }
      rating
      suggestedByCount
      location {
        longitude
        latitude
      }
      swiggyAvailable
      zomatoAvailable
      description
      addressLine
    }
  }
`;
