import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Checkbox, Col, Form, Input, message, Radio, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formValidatorRules } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonInput from '../../../components/CommonInput';
import ImageUpload from '../../../components/imageUpload/ImageUpload';
import SelectComponent from '../../../components/selectComponent/SelectComponent';
import Loader from '../../../Loader';
import { DIETARY_AND_DISH_TYPE } from '../graphql/Queries';

const { required } = formValidatorRules;

function CategoryForm({ mutation, id, categoryData, mutating }) {
  const history = useHistory();
  const [form] = useForm();
  const [initial, setInitial] = useState({});
  const [image, setImage] = useState('');

  useEffect(() => {
    if (categoryData) {
      const initialValuesObject = {
        ...categoryData,
        city: categoryData.city.id,
        dietaryType: categoryData.dietaryType?.map((dietary) => {
          return parseInt(dietary.id, 10);
        })
      };
      setInitial(initialValuesObject);
    }
  }, [categoryData]);
  useEffect(() => {
    form.resetFields();
  }, [form, initial]);

  const { data, loading, error: dataError } = useQuery(DIETARY_AND_DISH_TYPE);
  if (loading) return <Loader />;
  if (dataError) return <p>An Error Occured!!</p>;

  const onFinish = (e) => {
    const { name, cities, city, details, dishType, dietaryType } = e;

    const { id: dishId } = dishType;

    try {
      mutation({
        variables: {
          id: id || '',
          name,
          city,
          cities: cities?.map((selectedCities) => parseInt(selectedCities, 10)),
          details,
          dishType: parseInt(dishId, 10),
          dietaryType,
          image
        }
      })
        ?.then(() => {
          history?.goBack();
        })
        ?.catch(() => {});
    } catch (error) {
      message?.error('Something went wrong!');
    }
  };

  return (
    <CommonCard
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <CommonButton
              onClick={() => {
                history?.goBack();
              }}
              type="default"
            >
              <LeftOutlined />
            </CommonButton>
          </Col>
          <Col>
            <h3>Category Details</h3>
          </Col>
          <Row gutter={5}>
            <Col>
              <CommonButton
                onClick={() => {
                  history?.goBack();
                }}
                type="default"
              >
                Discard
              </CommonButton>
            </Col>
            <Col>
              <Form form={form} onFinish={onFinish} initialValues={initial}>
                <CommonButton
                  type="primary"
                  htmlType="submit"
                  loading={mutating}
                >
                  Save
                </CommonButton>
              </Form>
            </Col>
          </Row>
        </Row>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initial}
        layout="vertical"
      >
        <Row gutter={24} justify="space-between">
          <Col span={14}>
            <Form.Item label="Category name" name="name" rules={[required]}>
              <CommonInput placeHolder="Category name" />
            </Form.Item>
            <Form.Item
              name={categoryData ? 'city' : 'cities'}
              rules={
                categoryData
                  ? [{ required }]
                  : [{ required, message: 'Required', type: 'array' }]
              }
              label="Select City"
            >
              <SelectComponent
                placeHolder="Select City"
                mode={categoryData ? '' : 'multiple'}
                disabled={categoryData}
                showArrow
              >
                {data?.getAllCities?.cities?.map((city) => {
                  return (
                    <Select.Option value={city?.id} key={city?.id}>
                      {city?.name}
                    </Select.Option>
                  );
                })}
              </SelectComponent>
            </Form.Item>
            <Form.Item
              label="Category details"
              name="details"
              rules={[required]}
            >
              <Input.TextArea rows={4} placeHolder="Category details" />
            </Form.Item>
            <Row>
              <Col>
                <Form.Item
                  label="Dietary Type"
                  name="dietaryType"
                  rules={[required]}
                >
                  <Checkbox.Group>
                    <Row gutter={[24, 16]}>
                      {data?.dietaryTypes?.map((dietary) => {
                        return (
                          <Col span={24} key={dietary?.id}>
                            <Checkbox value={parseInt(dietary?.id, 10)}>
                              {dietary?.type}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Dish Type"
                  name={['dishType', 'id']}
                  rules={[required]}
                >
                  <Radio.Group>
                    <Row gutter={[0, 16]}>
                      {data?.dishTypes?.map((dish) => {
                        return (
                          <Col span={24} key={dish?.id}>
                            <Radio value={`${dish?.id}`}>{dish?.type}</Radio>
                          </Col>
                        );
                      })}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={9}>
            <Form.Item label="Category Image" name="details">
              <ImageUpload
                setImageUrl={setImage}
                existingImageSrc={categoryData?.imageUrl}
                imageKey="DISH_CATEGORY"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CommonCard>
  );
}

export default CategoryForm;
