import { UserOutlined } from '@ant-design/icons';
import { Col, Form, message, Row, Spin } from 'antd';
import React, { useState } from 'react';
import client from '../../apollo';
import Logo from '../../assets/logo.png';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonButton from '../../components/CommonButton';
import CommonInput from '../../components/CommonInput';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ResetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    client
      ?.mutate({
        mutation: FORGOT_PASSWORD,
        variables: values
      })
      ?.catch((error) => {
        if (error && error.message) {
          return error;
        }
        message?.error('Something went wrong');
      })
      ?.finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="gx-mb-4">
          <h2>Forgot Your Password ?</h2>
          <p>
            Don't worry. Recovering the password is easy.Just tell us the email.
          </p>
        </div>
        <Spin spinning={false}>
          <Form
            layout="vertical"
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item name="email" rules={[required, email]}>
              <CommonInput
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Email"
              />
            </Form.Item>
            <Row gutter={16}>
              <Col>
                <Form.Item>
                  <CommonButton
                    type="primary"
                    loading={loading}
                    className="mr-2"
                    htmlType="submit"
                  >
                    Reset Password
                  </CommonButton>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <CommonButton
                    onClick={() => {
                      props?.history?.push(ROUTES?.LOGIN);
                    }}
                  >
                    Cancel
                  </CommonButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ResetPassword;
