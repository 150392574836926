import { gql } from '@apollo/client';

export const BLOCK_USER = gql`
  mutation blockUser($id: Int!) {
    blockUser(id: $id)
  }
`;

export const UNBLOCK_USER = gql`
  mutation unBlockUser($id: Int!) {
    unBlockUser(id: $id)
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      message
    }
  }
`;
