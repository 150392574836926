import { Tag } from 'antd';
import React from 'react';

function CommonTag({ label, ...props }) {
  return (
    <div>
      <Tag className="mb-8 text-wrap" {...props}>
        {label}
      </Tag>
    </div>
  );
}

export default CommonTag;
