import { Card } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const ErrorComponent = () => (
  <Card>
    <div className="gx-page-error-container">
      <div className="gx-page-error-content">
        <h2 className="gx-text-center">
          Oops, Server returned an error. Please Wait!
        </h2>
        <p className="gx-text-center">
          <Link className="gx-btn gx-btn-primary" to="/">
            Home
          </Link>
        </p>
      </div>
    </div>
  </Card>
);

export default ErrorComponent;
