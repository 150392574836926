import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ContactUsTable from './components/ContactUsTable';

const ContactForm = () => {
  return (
    <div>
      <Switch>
        <Route path={ROUTES?.CONTACT_FORM} component={ContactUsTable} exact />
      </Switch>
    </div>
  );
};

export default ContactForm;
