import { EllipsisOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Menu,
  message,
  Popconfirm,
  Popover,
  Row,
  Spin,
  Tabs,
  Tooltip,
  Typography
} from 'antd';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  FOODIE_STATES,
  PAGINATION_DEFAULT_PAGE_SIZE,
  ROUTES
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { BLOCK_USER, DELETE_USER, UNBLOCK_USER } from '../graphql/Mutations';
import { GET_FOODIES } from '../graphql/Queries';

function FoodiesTable() {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [filteredInfo, setFilteredInfo] = useState([]);
  const [foodieCities, setFoodieCities] = useState([]);
  const [skip, setSkip] = useState(0);
  const [visible, setVisible] = useState(false);
  const [blockVisible, setBlockVisible] = useState(false);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState('updatedAtDesc');
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const history = useHistory();
  const [status, setStatus] = useState(FOODIE_STATES?.ACTIVE);
  const { data, error, loading } = useQuery(GET_FOODIES, {
    variables: { skip, limit, status, sortBy },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: PAGINATION_DEFAULT_PAGE_SIZE,
        total: res?.users?.count
      };
      setPaginationProp(pagination);
    }
  });
  const [deleteUser, { loading: deleteUserLoading }] = useMutation(DELETE_USER);
  const [blockUser, { loading: blockUserLoading }] = useMutation(BLOCK_USER);
  const [unBlockUser, { loading: unBlockUserLoading }] = useMutation(
    UNBLOCK_USER
  );

  const onDeleteHandler = (userId) => {
    deleteUser({
      variables: {
        id: userId
      },
      refetchQueries: [
        {
          query: GET_FOODIES,
          variables: { status }
        }
      ]
    });
  };

  const onBlockHandler = (userId) => {
    blockUser({
      variables: {
        id: userId
      },
      refetchQueries: [
        {
          query: GET_FOODIES,
          variables: { status: FOODIE_STATES?.ACTIVE }
        }
      ]
    })?.then(() => {
      message?.success('User blocked successfully');
    });
  };

  const onUnBlockHandler = (userId) => {
    unBlockUser({
      variables: {
        id: parseInt(userId, 10)
      },
      refetchQueries: [
        {
          query: GET_FOODIES,
          variables: { status: FOODIE_STATES?.BLOCKED }
        }
      ]
    })?.then(() => {
      message?.success('User unblocked successfully');
    });
  };

  const columns = [
    {
      title: 'Foodies Name',
      dataIndex: 'firstName',

      key: '1',
      fixed: 'left',
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      render: (firstName) => (
        <Tooltip placement="topLeft" title={firstName}>
          {firstName}
        </Tooltip>
      )
    },
    {
      title: 'City',
      dataIndex: ['city', 'name'],
      key: '2',
      align: 'center',
      filters:
        foodieCities?.length &&
        foodieCities?.sort()?.map((city) => {
          return { text: city, value: city };
        }),
      filteredValue: filteredInfo[2] || null,
      onFilter: (value, record) => record?.city?.name?.includes(value)
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: '3',
      align: 'center',
      sorter: true
    },
    {
      title: 'Reviews',
      dataIndex: 'userReviewCount',
      key: '4',
      align: 'center',
      sorter: true
    },
    {
      title: 'Suggestions',
      dataIndex: 'usersSuggestionsCount',
      key: '5',
      align: 'center',
      sorter: true
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: '6',
      align: 'center',
      sorter: true,
      render: (createdAt) => {
        return (
          <Typography.Text>{formatDate(createdAt, 'DD/MM/YY')}</Typography.Text>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '8',
      hidden: currentUser?.role !== 'SUPER_ADMIN',
      render: (value) => {
        return (
          <Row justify="start">
            <Popover
              content={
                <Menu>
                  <Menu.Item>
                    <Popconfirm
                      title={`Are you sure you want to ${
                        status === FOODIE_STATES?.ACTIVE ? 'Block' : 'Unblock'
                      } this foodie?`}
                      visible={blockVisible}
                      trigger="hover"
                      onConfirm={(e) => {
                        e?.stopPropagation();
                        setBlockVisible(false);
                        if (status === FOODIE_STATES?.BLOCKED) {
                          onUnBlockHandler(parseInt(value?.id, 10));
                        } else {
                          onBlockHandler(parseInt(value?.id, 10));
                        }
                      }}
                      cancelButtonProps={{
                        shape: 'round',
                        size: 'middle'
                      }}
                      okText="Yes"
                      cancelText="No"
                      okButtonProps={{
                        shape: 'round',
                        size: 'middle',
                        type: 'default'
                      }}
                      onCancel={(e) => {
                        e?.stopPropagation();
                        setBlockVisible(false);
                      }}
                      icon={null}
                    >
                      <Button
                        type="text"
                        onClick={(e) => {
                          e?.stopPropagation();
                          setBlockVisible(true);
                        }}
                      >
                        {status === FOODIE_STATES?.ACTIVE ? 'Block' : 'Unblock'}
                      </Button>
                    </Popconfirm>
                  </Menu.Item>

                  <Menu.Item>
                    <Popconfirm
                      title="Are you sure you want to delete this foodie?"
                      visible={visible}
                      trigger="hover"
                      onConfirm={(e) => {
                        e?.stopPropagation();
                        setVisible(false);
                        onDeleteHandler(parseInt(value?.id, 10));
                      }}
                      cancelButtonProps={{
                        shape: 'round',
                        size: 'middle'
                      }}
                      okText="Yes"
                      cancelText="No"
                      okButtonProps={{
                        shape: 'round',
                        size: 'middle',
                        type: 'default'
                      }}
                      onCancel={(e) => {
                        e?.stopPropagation();
                        setVisible(false);
                      }}
                      icon={null}
                    >
                      <Button
                        type="text"
                        onClick={(e) => {
                          e?.stopPropagation();
                          setVisible(true);
                        }}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <CommonButton
                type="text"
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              >
                <EllipsisOutlined />
              </CommonButton>
            </Popover>
          </Row>
        );
      }
    }
  ]?.filter((item) => !item?.hidden);

  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;
    setFilteredInfo(filters);
    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;
      setSkip(0);
      setLimit(10);

      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('firstNameAsc');
              break;
            case '3':
              setSortBy('levelAsc');
              break;
            case '4':
              setSortBy('reviewsAsc');
              break;
            case '5':
              setSortBy('suggestionsAsc');
              break;
            case '6':
              setSortBy('createdAtAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('firstNameDesc');
              break;
            case '3':
              setSortBy('levelDesc');
              break;
            case '4':
              setSortBy('reviewsDesc');
              break;
            case '5':
              setSortBy('suggestionsDesc');
              break;
            case '6':
              setSortBy('createdAtDesc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        default:
          setSortBy('updatedAtDesc');
      }
    }
    if (!_isEmpty(pagination)) {
      setSkip(skipNumber);
      setLimit(pagination?.pageSize);
      setPaginationProp({ ...paginationProp, ...pagination });
    }
  };
  useEffect(() => {
    data?.users?.users?.forEach((user) => {
      if (!foodieCities?.includes(user?.city?.name)) {
        setFoodieCities([...foodieCities, user?.city?.name]);
      }
    });
  }, [data?.users?.users, foodieCities]);

  if (error) {
    return <ErrorComponent />;
  }
  if (loading && !data) {
    return <Loader />;
  }
  const onTabClick = (key) => {
    setStatus(key);
  };

  return (
    <Spin
      spinning={deleteUserLoading || blockUserLoading || unBlockUserLoading}
    >
      <CommonCard className="tabs-card">
        <Tabs onTabClick={onTabClick} activeKey={status}>
          <Tabs.TabPane tab="Foodies" key={FOODIE_STATES?.ACTIVE}>
            <CommonTable
              columns={columns}
              rowKey={(e) => e?.id}
              data={data?.users?.users}
              scroll={{ x: 1000 }}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              onRow={(record) => {
                return {
                  onClick: () => {
                    history?.push(`${ROUTES?.FOODIES}/${record?.id}`);
                  }
                };
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Blocked foodie" key={FOODIE_STATES?.BLOCKED}>
            <CommonTable
              columns={columns}
              data={data?.users?.users}
              rowKey={(e) => e?.id}
              scroll={{ x: 1000 }}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              onRow={(record) => {
                return {
                  onClick: () => {
                    history?.push(`${ROUTES?.FOODIES}/${record?.id}`);
                  }
                };
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </CommonCard>
    </Spin>
  );
}

export default FoodiesTable;
