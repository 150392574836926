import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './app/App';
import { AppContext } from './AppContext';
import './assets/vendors/style';
import { ROUTES } from './common/constants';
import ChangePassword from './modules/auth/ChangePassword';
import Login from './modules/auth/Login';
import RefreshToken from './modules/auth/RefreshToken';
import ResetPassword from './modules/auth/ResetPassword';
import Verify from './modules/auth/Verify';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  const { initializeAuth, dispatch } = useContext(AppContext);

  useEffect(() => {
    initializeAuth();
    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path={ROUTES.LOGOUT}
          render={() => {
            // eslint-disable-next-line no-undef
            window.location = '/login';
            dispatch({ type: 'LOGOUT' });
            return null;
          }}
        />
        <PublicRoute exact path={ROUTES.RESET} component={ResetPassword} />
        <PublicRoute exact path={ROUTES.LOGIN} component={Login} />
        <PublicRoute exact path={ROUTES.RESET_PASSWORD} component={Verify} />
        <PublicRoute exact path={ROUTES.VERIFY} component={Verify} />
        <PrivateRoute exact path={ROUTES.CHANGE} component={ChangePassword} />
        <PrivateRoute
          exact
          path={ROUTES.AUTHENTICATION}
          component={RefreshToken}
        />
        <PrivateRoute path="/" component={App} />
      </Switch>
    </Router>
  );
};
export default Routes;
