import { gql } from '@apollo/client';

export const ADD_NEW_CITY = gql`
  mutation addNewCity($name: String!, $description: String!) {
    addNewCity(input: { name: $name, description: $description }) {
      data {
        id
        name
      }
      message
    }
  }
`;

export default ADD_NEW_CITY;

export const DELETE_CITY = gql`
  mutation deleteCity($id: ID!, $force: Boolean) {
    deleteCity(id: $id, force: $force) {
      message
    }
  }
`;

export const UPDATE_CITY_STATUS = gql`
  mutation($status: Status!, $id: ID!) {
    updateCityStatus(id: $id, status: $status) {
      message
    }
  }
`;
