import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { findLocationInfoByLatLng } from '../../common/utils';

function Map({ initialPosition, setLocationInfo, form, setAddress }) {
  const [currentPosition, setCurrentPosition] = useState({});
  useEffect(() => {
    setCurrentPosition({
      lat: initialPosition?.latitude || 21.20351,
      lng: initialPosition?.longitude || 72.839233
    });
  }, [initialPosition]);
  const containerStyle = {
    height: '250px',
    borderRadius: '8px'
  };

  const onMarkerDragEnd = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentPosition({ lat, lng });
    const locationInformation = await findLocationInfoByLatLng(lat, lng);
    setLocationInfo({
      ...locationInformation
    });
    setAddress(locationInformation?.address);

    form.setFieldsValue({
      ...locationInformation
    });
  };

  return (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={13}
        center={currentPosition}
      >
        {currentPosition?.lat && currentPosition?.lng && (
          <Marker
            position={currentPosition}
            onDragEnd={(e) => onMarkerDragEnd(e)}
            draggable
          />
        )}
      </GoogleMap>
    </div>
  );
}

export default React.memo(Map);
