import { gql } from '@apollo/client';

export const GET_ALL_REVIEWS = gql`
  query reviews(
    $limit: Int
    $skip: Int
    $sortBy: reviewSortBy
    $status: ReviewStatus!
  ) {
    reviews(
      filter: { sortBy: $sortBy, skip: $skip, limit: $limit }
      status: $status
    ) {
      count
      reviews {
        id
        foodie {
          name
          id
        }
        eatery {
          name
          id
        }
        rating
        createdAt
      }
    }
  }
`;
export const REVIEW = gql`
  query review($id: ID!) {
    review(id: $id) {
      foodie {
        id
        name
        level
        followerCount
      }
      eatery {
        id
        name
      }
      rating
      reviewGiven
      reviewCount
      reviewStatus
      suggestionCount
      favouriteDish {
        id
        name
      }
      imageUrls {
        id
        url
      }
    }
  }
`;
