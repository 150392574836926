import { useQuery } from '@apollo/client';
import { Button, Col, Modal, Tooltip } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { PAGINATION_DEFAULT_PAGE_SIZE } from '../../../common/constants';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import CONTACT_US_LIST from '../graphql/queries';

const ContactUsTable = () => {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [sortBy, setSortBy] = useState('updatedAtDesc');
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });

  const filter = {
    skip,
    limit,
    sortBy
  };

  const { data, loading, error } = useQuery(CONTACT_US_LIST, {
    variables: { filter },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: PAGINATION_DEFAULT_PAGE_SIZE,
        total: res?.contactUsList?.count
      };
      setPaginationProp(pagination);
    }
  });

  if (loading && !data) return <Loader />;
  if (error) return <ErrorComponent />;

  const columns = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: '1',
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      )
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: '2',
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      render: (lastName) => (
        <Tooltip placement="topLeft" title={lastName}>
          {lastName}
        </Tooltip>
      )
    },
    {
      title: 'Phone Number',
      dataIndex: 'contactNumber',
      key: '3'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '30%',
      sorter: true,
      key: '4'
    },

    {
      title: 'Message',
      dataIndex: 'message',
      key: '5',

      render: (message) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setMessageModalVisible(true);
              setModalMessage(message);
            }}
          >
            View
          </Button>
        </>
      )
    }
  ];

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;

    if (!_isEmpty(pagination)) {
      setSkip(skipNumber);
      setLimit(pagination?.pageSize);
      setPaginationProp({ ...paginationProp, ...pagination });
    }
    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;
      setSkip(0);
      setLimit(10);

      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('firstNameAsc');
              break;
            case '2':
              setSortBy('lastNameAsc');
              break;
            case '4':
              setSortBy('emailAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('firstNameDesc');
              break;
            case '2':
              setSortBy('lastNameDesc');
              break;
            case '4':
              setSortBy('emailDesc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        default:
          setSortBy('updatedAtDesc');
      }
    }
  };
  return (
    <CommonCard
      className="table-card"
      title={
        <Col>
          <h3 className="mb-0">Contact Form</h3>
        </Col>
      }
    >
      <Modal
        visible={messageModalVisible}
        onCancel={() => {
          setMessageModalVisible(false);
        }}
        title="Messsage"
        footer={null}
      >
        {modalMessage || '-'}
      </Modal>
      <CommonTable
        loading={loading}
        rowKey={(e) => e?.id}
        columns={columns}
        data={data?.contactUsList?.userList}
        paginationConfig={paginationProp}
        onChange={handleTableChange}
        tableRowIsClickable={false}
      />
    </CommonCard>
  );
};

export default ContactUsTable;
