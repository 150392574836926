import { gql } from '@apollo/client';

export const GET_ALL_CITIES = gql`
  query getAllCities(
    $status: Status
    $skip: Int
    $limit: Int
    $sortBy: CitySortBy
  ) {
    getAllCities(
      filter: { sortBy: $sortBy, skip: $skip, limit: $limit }
      status: $status
    ) {
      count
      cities {
        id
        name
        dishCount
        eateryCount
      }
    }
  }
`;

export const CITY = gql`
  query getCity($id: ID!) {
    getCity(id: $id) {
      id
      name
      eateryCount
      dishCount
      status
      description
    }
  }
`;
