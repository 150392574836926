import { useMutation } from '@apollo/client';
import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import CommonModal from '../../../components/CommonModal';
import { UPDATE_SUGGESTION_STATUS } from '../graphql/Mutations';
import { GET_ALL_SUGGESTIONS } from '../graphql/Queries';
import SelectEatery from './SelectEatery';

function AssignToModal({ defaultVisible }) {
  const { id } = useParams();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(defaultVisible);

  const [form] = useForm();
  const [updateSuggestionStatus, { loading: mutating }] = useMutation(
    UPDATE_SUGGESTION_STATUS,
    {
      refetchQueries: [
        {
          query: GET_ALL_SUGGESTIONS,
          variables: { status: 'PENDING' },
          fetchPolicy: 'cache-and-network'
        }
      ]
    }
  );
  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };
  const onFinish = (e) => {
    const { assigned } = e;

    updateSuggestionStatus({
      variables: { id, isRejected: false, assigned }
    })
      .then(() => {
        setModalVisible(false);
        history.push(ROUTES.SUGGESTIONS);
      })
      .catch(() => {});
  };
  return (
    <div>
      <CommonButton type="default" onClick={showModal}>
        Accept
      </CommonButton>
      <CommonModal
        okText="Assign"
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={modalVisible}
        okButtonProps={{ loading: mutating }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <SelectEatery label="Assign to" name="assigned" />
          <Row align="middle">
            <Col>
              Create a eatery from
              <Link to={`${ROUTES.EATERY_ADD}?suggestionId=${id}`}> here </Link>
              if not found in search
            </Col>
          </Row>
        </Form>
      </CommonModal>
    </div>
  );
}

export default AssignToModal;
