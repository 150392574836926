import {
  DeleteOutlined,
  EllipsisOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Menu, Popover, Row, Tooltip } from 'antd';
import { get } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  ROUTES
} from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { DELETE_EATERY } from '../graphql/Mutations';
import { GET_ALL_EATERIES } from '../graphql/Queries';
import DeleteEatery from './DeleteEatery';

function EateryTable() {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const [skip, setSkip] = useState(0);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [limit, setLimit] = useState(10);
  const [selectedEatery, setSelectedEatery] = useState();
  const [sortBy, setSortBy] = useState('updatedAtDesc');
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const [eateryCities, setEateryCities] = useState([]);
  const [filterInfo, setFilterInfo] = useState([]);
  const rowSelection = {
    checkStrictly: true,
    selectedRowKeys: selectedEatery,
    onChange: (rows) => {
      setSelectedEatery(rows);
    }
  };
  const [deleteEatery, { loading: deletingEatery }] = useMutation(
    DELETE_EATERY,
    {
      refetchQueries: [
        {
          query: GET_ALL_EATERIES,
          fetchPolicy: 'cache-and-network'
        }
      ]
    }
  );
  const history = useHistory();
  const { data, loading, error } = useQuery(GET_ALL_EATERIES, {
    variables: { skip, limit, sortBy },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: PAGINATION_DEFAULT_PAGE_SIZE,
        total: res?.eateries?.count
      };
      setPaginationProp(pagination);
    }
  });

  if (loading && !data) return <Loader />;
  if (error) return <ErrorComponent />;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: '1',
      sorter: true,
      fixed: 'left',
      className: 'font-semibold',
      ellipsis: {
        showTitle: false
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      )
    },
    {
      title: 'City',
      dataIndex: ['city', 'name'],
      sorter: true,
      key: '2',
      filters:
        eateryCities?.length &&
        eateryCities?.sort()?.map((city) => {
          return { text: city, value: city };
        }),
      filteredValue: get(filterInfo, 2) || null,
      onFilter: (value, record) => {
        return record?.city?.name?.includes(value);
      }
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: '3',
      align: 'center',
      render: (featured) => {
        if (featured) {
          return <StarFilled />;
        }
        return <StarOutlined />;
      }
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: '4',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Reviews',
      dataIndex: 'reviewsCount',
      key: '5',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Menu Item',
      dataIndex: 'dishCount',
      sorter: true,
      key: '6',
      align: 'center'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '7',
      align: 'center',
      render: (value) => {
        const { id } = value;

        return (
          <Row justify="start">
            <Popover
              content={
                <Menu>
                  <Menu.Item>
                    <CommonButton
                      type="text"
                      onClick={(e) => {
                        e?.stopPropagation();
                        history?.push(`${ROUTES?.EATERY}/edit/${value?.id}`);
                      }}
                    >
                      Edit
                    </CommonButton>
                  </Menu.Item>
                  {user?.role === 'SUPER_ADMIN' && (
                    <Menu.Item>
                      <DeleteEatery id={id} />
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <CommonButton
                type="text"
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              >
                <EllipsisOutlined />
              </CommonButton>
            </Popover>
          </Row>
        );
      }
    }
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;
    setFilterInfo(filters);

    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;
      setSkip(0);
      setLimit(10);
      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('nameAsc');
              break;
            case '2':
              setSortBy('cityNameAsc');
              break;
            case '4':
              setSortBy('ratingAsc');
              break;
            case '5':
              setSortBy('reviewsCountAsc');
              break;
            case '6':
              setSortBy('dishCountAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('nameDesc');
              break;
            case '2':
              setSortBy('cityNameDesc');
              break;
            case '4':
              setSortBy('ratingDesc');
              break;
            case '5':
              setSortBy('reviewsCountDesc');
              break;
            case '6':
              setSortBy('dishCountDesc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        default:
          setSortBy('updatedAtDesc');
      }
    }
    if (!_isEmpty(pagination)) {
      setSkip(skipNumber);
      setLimit(pagination?.pageSize);
      setPaginationProp({ ...paginationProp, ...pagination });
    }
  };

  data?.eateries?.eateries?.forEach((eatery) => {
    if (!eateryCities?.includes(eatery?.city?.name)) {
      setEateryCities([...eateryCities, eatery?.city?.name]);
    }
  });

  return (
    <CommonCard
      className="table-card"
      title={
        <>
          <Row justify="space-between" align="middle" gutter={16}>
            <Col>
              <h3 className="mb-0">Eateries</h3>
            </Col>
            <Col>
              <Row gutter={16}>
                <Col>
                  {user?.role === 'SUPER_ADMIN' && selectedEatery?.length > 0 && (
                    <>
                      <Popover
                        onClick={(e) => {
                          e?.stopPropagation();
                        }}
                        visible={deleteConfirm}
                        trigger="click"
                        content={
                          <>
                            <p>Are you sure you want to delete</p>
                            <Row>
                              <Col>
                                <CommonButton
                                  onClick={(e) => {
                                    e?.stopPropagation();

                                    deleteEatery({
                                      variables: {
                                        id: selectedEatery
                                      }
                                    });
                                    setDeleteConfirm(false);
                                    setSelectedEatery([]);
                                  }}
                                  loading={deletingEatery}
                                >
                                  yes
                                </CommonButton>
                              </Col>
                              &nbsp;
                              <Col>
                                <CommonButton
                                  onClick={(e) => {
                                    e?.stopPropagation();
                                    setDeleteConfirm(false);
                                    setSelectedEatery([]);
                                  }}
                                >
                                  No
                                </CommonButton>
                              </Col>
                            </Row>
                          </>
                        }
                      />
                      <CommonButton
                        loading={deletingEatery}
                        type="primary"
                        className="btn-1"
                        onClick={() => {
                          deleteEatery({
                            variables: {
                              id: selectedEatery
                            }
                          });
                        }}
                      >
                        <DeleteOutlined /> Delete
                      </CommonButton>
                    </>
                  )}
                </Col>
                <Col>
                  {' '}
                  <CommonButton
                    type="primary"
                    onClick={() => {
                      history?.push(`${ROUTES?.EATERY_ADD}`);
                    }}
                    className="btn-2"
                  >
                    <PlusOutlined /> Add Eatery
                  </CommonButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      }
    >
      <div>
        <CommonTable
          rowKey={(e) => e?.id}
          columns={columns}
          data={data?.eateries?.eateries}
          rowSelection={user?.role === 'SUPER_ADMIN' ? rowSelection : false}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          onRow={(record) => {
            return {
              onClick: () => {
                history?.push(`${ROUTES?.EATERY}/${record?.id}`);
              }
            };
          }}
        />
      </div>
    </CommonCard>
  );
}

export default EateryTable;
