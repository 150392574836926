import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AdminUserTable from './components/AdminUserTable';

function AdminUser() {
  return (
    <div>
      {' '}
      <Switch>
        <Route path={ROUTES.ADMIN_USERS} component={AdminUserTable} exact />
      </Switch>
    </div>
  );
}

export default AdminUser;
