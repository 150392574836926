import { gql } from '@apollo/client';

export const GET_ALL_EATERIES = gql`
  query eateries($skip: Int, $limit: Int, $sortBy: EaterySortBy) {
    eateries(filter: { sortBy: $sortBy, skip: $skip, limit: $limit }) {
      count
      eateries {
        id
        name
        description
        featured
        city {
          id
          name
        }
        rating
        reviewsCount
        dishCount
      }
    }
  }
`;

export const GET_EATERY_BY_ID = gql`
  query singleEatery($id: ID!, $sortBy: MenuSortByEnum) {
    singleEatery(id: $id, menuFilter: { sortBy: $sortBy }) {
      id
      name
      contactNo
      description
      addressLine
      rating
      famousFor {
        id
        dishName
      }
      city {
        id
        name
      }
      eateryTiming {
        weekdayOpen
        weekdayClose
        isSameAsWeekday
        weekendOpen
        weekendClose
      }
      menu {
        id
        price
        dishName
        featured
        suggestedBy
        dishType {
          id
          type
        }
        dietaryType {
          id
          type
        }
      }
      reviewsCount
      dishCount
      location {
        longitude
        latitude
      }
      imageUrl
      swiggyAvailable
      zomatoAvailable
    }
  }
`;
export const SORT_EATERIES = gql`
  query singleEatery($id: ID!, $sortBy: MenuSortByEnum) {
    singleEatery(id: $id, menuFilter: { sortBy: $sortBy }) {
      id
      menu {
        id
        price
        dishName
        featured
        suggestedBy
        dishType {
          id
          type
        }
        dietaryType {
          id
          type
        }
      }
    }
  }
`;

export const GET_EATERY_MENU = gql`
  query eateryMenu($id: ID!) {
    EateryMenu(id: $id) {
      dishes {
        id
        dishName
        dishType {
          id
          type
        }
        dietaryType {
          id
          type
        }
        price
        suggestedBy
      }
    }
  }
`;

export const GET_DISH_BY_ID = gql`
  query singleDish($id: ID!) {
    singleDish(id: $id) {
      id
      eateryId
      name
      price
      dietaryType {
        id
        type
      }
      dishCategory {
        id
        name
      }
      imageUrl
      dishType {
        id
        type
      }
    }
  }
`;
