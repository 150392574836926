import { gql } from '@apollo/client';

export const UPDATE_SUGGESTION_STATUS = gql`
  mutation(
    $id: ID!
    $isRejected: Boolean
    $assigned: Int
    $rejectionMessage: String
  ) {
    updateSuggestionStatus(
      id: $id
      input: {
        isRejected: $isRejected
        assigned: $assigned
        rejectionMessage: $rejectionMessage
      }
    ) {
      data {
        id
        suggestionStatus
        eateryName
        foodieName
      }
      message
    }
  }
`;

export default UPDATE_SUGGESTION_STATUS;
