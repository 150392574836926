import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import EateryReportDetails from './component/EateryReportDetails';
import ReportDetails from './component/ReportDetails';
import ReportTable from './component/ReportTable';

function Report() {
  return (
    <Switch>
      <Route path={ROUTES?.REPORT} component={ReportTable} exact />
      <Route exact path={ROUTES?.REPORT_DETAILS} component={ReportDetails} />
      <Route path={ROUTES?.REPORTED_EATERIES} component={EateryReportDetails} />
    </Switch>
  );
}

export default Report;
