/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  RESET: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id/:token',
  CHANGE: '/reset',
  VERIFY: '/verify/:id/:token',
  EATERY: '/eatery',
  EATERY_ADD: '/eatery/add',
  EATERY_DETAILS: '/eatery/:id',
  EDIT_EATERY: '/eatery/edit/:id',
  CITY: '/city',
  CITY_DETAILS: '/city/:id',
  DISH_CATEGORIES: '/dish-categories',
  FOODIES: '/foodies',
  FOODIES_PROFILE: '/foodies/:id',
  SUGGESTIONS: '/suggestions',
  SUGGESTION_DETAILS: '/suggestions/:id',
  SUGGESTION_ACCEPT: '/suggestions/accept',
  SUGGESTION_DECLINE: '/suggestions/decline',
  REVIEWS: '/reviews',
  REVIEW_DETAILS: '/reviews/:id',
  ADMIN_USERS: '/adminUsers',
  ADMIN_USERS_ADD: '/adminUsers/add',
  CATEGORIES_VIEW: '/dish-categories/:id',
  CATEGORIES_ADD: '/dish-categories/add',
  CATEGORIES_EDIT: '/dish-categories/edit/:id',
  REPORT: '/report',
  REPORTED_EATERIES: '/report/eatery/:id',
  REPORT_DETAILS: '/report/:id',
  AUTHENTICATION: '/authentication',
  CONTACT_FORM: '/contact-form'
};

/*  Modules */
export const MODULES = {
  EATERY: 'Eatery',
  CITY: 'City',
  DISH_CATEGORIES: 'Dish Categories',
  REPORT: 'Report',
  FOODIES: 'Foodies',
  SUGGESTIONS: 'Suggestion',
  REVIEWS: 'Reviews',
  MESSAGES: 'Messages',
  ADMIN_USERS: 'Admin users',
  CONTACT_FORM: 'Contact form'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN'
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[0-9()+ ]+$/i,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};

export const API_KEY = {
  GOOGLE_API_KEY: 'AIzaSyB71fBsJ4_WFRfwbMIOqYxgZEUExzd0FCw'
};
export const CITY_STATUS = {
  LIVE: 'LIVE',
  DRAFT: 'DRAFT'
};
export const FOODIE_STATES = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED'
};
export const PAGE_OPTIONS = [10, 15, 20, 50, 100];
export const PAGINATION_DEFAULT_PAGE_SIZE = 10;

export const PROD_WEB_URL = 'https://localfoodie.in/';
export const DEV_WEB_URL = 'https://dev.localfoodie.in/';
