import { useMutation } from '@apollo/client';
import { Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonModal from '../../../components/CommonModal';
import { HIDE_REVIEW } from '../graphql/Mutations';

const { required } = formValidatorRules;

function HideReview() {
  const [form] = useForm();
  const { id } = useParams();
  const history = useHistory();
  const [hideReview] = useMutation(HIDE_REVIEW);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const onFinish = (e) => {
    const { removeMessage } = e;
    setLoading(true);
    hideReview({ variables: { removeMessage, id } })
      .then(() => {
        setLoading(false);
        setModalVisible(false);
        history.push(`${ROUTES.REVIEWS}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <CommonButton type="default" onClick={showModal}>
        Hide
      </CommonButton>
      <CommonModal
        okText="Hide"
        title="Reason for hiding this review"
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={modalVisible}
        okButtonProps={{ loading: loading }}
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item label="Reason" name="removeMessage" rules={[required]}>
            <Input />
          </Form.Item>
        </Form>
      </CommonModal>
    </div>
  );
}

export default HideReview;
