import { gql } from '@apollo/client';

export const GET_ALL_SUGGESTIONS = gql`
  query suggestion(
    $skip: Int
    $limit: Int
    $sortBy: suggestionSortBy
    $status: SuggestionStatus!
  ) {
    Suggestions(
      filter: { sortBy: $sortBy, skip: $skip, limit: $limit }
      status: $status
    ) {
      count
      suggestions {
        id
        eateryName
        eateryCity
        knownForDishes
        createdAt
        foodieName
        suggestionStatus
      }
    }
  }
`;

export const GET_SUGGESTION_BY_ID = gql`
  query suggestion($id: ID!) {
    Suggestion(id: $id) {
      id
      eateryName
      eateryContactNo
      eateryCity
      foodieName
      suggestionStatus
      imageUrls {
        id
        url
      }
      knownForDishes {
        id
        name
      }
      location {
        longitude
        latitude
      }
    }
  }
`;
