import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Popover, Row, Tabs, Tooltip, Typography } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { DELETE_REPORTS } from '../graphql/Mutations';
import { GET_REPORTED_REVIEWS_EATERIES } from '../graphql/Queries';

function ReportTable() {
  const history = useHistory();
  const [type, setType] = useState('Review');
  const [visibleReview, setVisibleReview] = useState(false);
  const [visibleEatery, setVisibleEatery] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState('updatedAtDesc');
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const [selectedReports, setSelectedReports] = useState([]);

  const [deleteReports, { loading: deletingReports }] = useMutation(
    DELETE_REPORTS,
    {
      refetchQueries: [
        {
          query: GET_REPORTED_REVIEWS_EATERIES,
          variables: {
            sortBy: 'updatedAtDesc',
            type,
            skip,
            limit
          }
        }
      ]
    }
  );
  const { data, error, loading } = useQuery(GET_REPORTED_REVIEWS_EATERIES, {
    variables: { sortBy, type, skip, limit },
    fetchPolicy: 'network-only'
  });
  if (loading && !data) return <Loader />;
  if (error) return <ErrorComponent />;

  const rowSelection = {
    selectedRowKeys: selectedReports,
    onChange: (rows) => {
      setSelectedReports(rows);
    }
  };
  const columns = [
    {
      key: '1',
      dataIndex: 'eateryName',
      title: 'Written For',
      fixed: 'left',
      ellipsis: {
        showTitle: false
      },
      render: (eateryName, record) => {
        return (
          <Tooltip placement="topLeft" title={eateryName}>
            <Typography.Text
              onClick={(e) => {
                e?.stopPropagation();
                history?.push(`${ROUTES?.EATERY}/${record?.eateryId}`);
              }}
            >
              {eateryName}
            </Typography.Text>
          </Tooltip>
        );
      },
      sorter: true
    },
    {
      key: '2',
      dataIndex: 'writtenBy',
      title: 'Written By',
      ellipsis: {
        showTitle: false
      },
      render: (writtenBy, record) => {
        return (
          <Tooltip placement="topLeft" title={writtenBy}>
            <Typography.Text
              onClick={(e) => {
                e?.stopPropagation();
                history?.push(`${ROUTES?.FOODIES}/${record?.writtenById}`);
              }}
            >
              {writtenBy}
            </Typography.Text>
          </Tooltip>
        );
      },
      sorter: true
    },
    {
      key: '3',
      dataIndex: 'reportedBy',
      title: 'Reported By',
      ellipsis: {
        showTitle: false
      },
      render: (reportedBy, record) => {
        return (
          <Tooltip placement="topLeft" title={reportedBy}>
            <Typography.Text
              onClick={(e) => {
                e?.stopPropagation();
                history?.push(`${ROUTES?.FOODIES}/${record?.reportedById}`);
              }}
            >
              {reportedBy}
            </Typography.Text>
          </Tooltip>
        );
      },
      sorter: true
    },
    {
      key: '4',
      dataIndex: 'reason',
      title: 'Reason',
      ellipsis: {
        showTitle: false
      },
      render: (reason) => (
        <Tooltip placement="topLeft" title={reason}>
          {reason}
        </Tooltip>
      )
    },
    {
      key: '5',
      dataIndex: 'createdAt',
      title: 'Date',
      sorter: true,
      render: (createdAt) => {
        return (
          <Typography.Text>{formatDate(createdAt, 'DD/MM/YY')}</Typography.Text>
        );
      }
    }
  ];

  const eateryColumns = [
    {
      key: '1',
      dataIndex: 'eateryName',
      fixed: 'left',
      title: 'Written for',
      sorter: true,
      render: (eateryName, record) => {
        return (
          <Typography.Text
            onClick={(e) => {
              e?.stopPropagation();
              history?.push(`${ROUTES?.EATERY}/${record?.eateryId}`);
            }}
          >
            {eateryName}
          </Typography.Text>
        );
      }
    },
    {
      key: '2',
      dataIndex: 'reportedBy',
      title: 'Reported By',
      sorter: true,
      render: (reportedBy, record) => {
        return (
          <Typography.Text
            onClick={(e) => {
              e?.stopPropagation();
              history?.push(`${ROUTES?.FOODIES}/${record?.reportedById}`);
            }}
          >
            {reportedBy}
          </Typography.Text>
        );
      }
    },
    {
      key: '3',
      dataIndex: 'reason',
      title: 'Reason'
    },
    {
      key: '4',
      dataIndex: 'createdAt',
      title: 'Date',
      sorter: true,
      render: (createdAt) => {
        return (
          <Typography.Text>{formatDate(createdAt, 'DD/MM/YY')}</Typography.Text>
        );
      }
    }
  ];
  const onTabClick = (key) => {
    setType(key);
  };
  const handleTableChange = (pagination, _, sorter) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;

    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;
      setSkip(0);
      setLimit(10);

      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('eateryNameAsc');
              break;
            case '2':
              setSortBy('writtenByAsc');
              break;
            case '3':
              setSortBy('reportedByAsc');
              break;
            case '4':
              setSortBy('createdAtAsc');
              break;
            case '5':
              setSortBy('createdAtAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('eateryNameDesc');
              break;
            case '2':
              setSortBy('writtenByDesc');
              break;
            case '3':
              setSortBy('reportedByDesc');
              break;
            case '4':
              setSortBy('createdAtDesc');
              break;
            case '5':
              setSortBy('createdAtDesc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        default:
          setSortBy('updatedAtDesc');
      }
    }
    if (!_isEmpty(pagination)) {
      setSkip(skipNumber);
      setLimit(pagination?.pageSize);
      setPaginationProp({ ...paginationProp, ...pagination });
    }
  };

  return (
    <CommonCard className="tabs-card">
      <Tabs onTabClick={onTabClick} activeKey={type}>
        <Tabs.TabPane tab="Review" key="Review">
          {selectedReports?.length > 0 && (
            <Row justify="end" className="row-margin">
              <Col>
                <Popover
                  onClick={(e) => {
                    e?.stopPropagation();
                  }}
                  visible={visibleReview}
                  trigger="click"
                  content={
                    <>
                      <p>Are you sure you want to delete</p>
                      <Row>
                        <Col>
                          <CommonButton
                            onClick={(e) => {
                              e?.stopPropagation();
                              deleteReports({
                                variables: {
                                  ids: selectedReports
                                }
                              });
                              setVisibleReview(false);
                              setSelectedReports([]);
                            }}
                            loading={deletingReports}
                          >
                            yes
                          </CommonButton>
                        </Col>
                        &nbsp;
                        <Col>
                          <CommonButton
                            onClick={(e) => {
                              e?.stopPropagation();
                              setVisibleReview(false);
                              setSelectedReports([]);
                            }}
                          >
                            No
                          </CommonButton>
                        </Col>
                      </Row>
                    </>
                  }
                />
                <CommonButton
                  type="primary"
                  onClick={(e) => {
                    setVisibleReview(true);
                    e?.stopPropagation();
                  }}
                >
                  <DeleteOutlined /> Delete
                </CommonButton>
              </Col>
            </Row>
          )}
          <CommonTable
            columns={columns}
            rowSelection={rowSelection}
            loading={loading}
            rowKey={(e) => e?.id}
            data={data?.reportedReviewsAndEateries}
            paginationConfig={paginationProp}
            onChange={handleTableChange}
            onRow={(record) => {
              return {
                onClick: () => {
                  history?.push(`${ROUTES?.REPORT}/${record?.id}`);
                }
              };
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Eatery" key="Eatery">
          {selectedReports?.length > 0 && (
            <Row justify="end" className="row-margin">
              <Col>
                <Popover
                  onClick={(e) => {
                    e?.stopPropagation();
                  }}
                  visible={visibleEatery}
                  trigger="click"
                  content={
                    <>
                      <p>Are you sure you want to delete</p>
                      <Row>
                        <Col>
                          <CommonButton
                            onClick={(e) => {
                              e?.stopPropagation();
                              deleteReports({
                                variables: {
                                  ids: selectedReports
                                }
                              });
                              setVisibleReview(false);

                              setSelectedReports([]);
                            }}
                            loading={deletingReports}
                          >
                            yes
                          </CommonButton>
                        </Col>
                        &nbsp;
                        <Col>
                          <CommonButton
                            onClick={(e) => {
                              e?.stopPropagation();
                              setVisibleEatery(false);
                              setSelectedReports([]);
                            }}
                          >
                            No
                          </CommonButton>
                        </Col>
                      </Row>
                    </>
                  }
                />
                <CommonButton
                  loading={deletingReports}
                  type="primary"
                  onClick={(e) => {
                    setVisibleEatery(true);
                    e.stopPropagation();
                  }}
                >
                  <DeleteOutlined /> Delete
                </CommonButton>
              </Col>
            </Row>
          )}
          <CommonTable
            columns={eateryColumns}
            rowSelection={rowSelection}
            rowKey={(e) => e?.id}
            scroll={{ x: 600 }}
            onChange={handleTableChange}
            data={data?.reportedReviewsAndEateries}
            paginationConfig={paginationProp}
            loading={loading}
            onRow={(record) => {
              return {
                onClick: () => {
                  history?.push(
                    `${ROUTES?.REPORT}${ROUTES?.EATERY}/${record?.id}`
                  );
                }
              };
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </CommonCard>
  );
}

export default ReportTable;
