import { EllipsisOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Menu, Popover, Row, Tabs } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { CITY_STATUS, ROUTES } from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_ALL_CITIES } from '../graphql/Queries';
import AddCity from './AddCity';
import DeleteCity from './DeleteCity';
import EditCity from './EditCity';

function City() {
  const history = useHistory();
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const [status, setStatus] = useState(CITY_STATUS?.LIVE);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState('updatedAtDesc');
  const { data: cityData, loading, error } = useQuery(GET_ALL_CITIES, {
    variables: { status, skip, limit, sortBy },
    fetchPolicy: 'cache-and-network'
  });
  if (loading && !cityData) return <Loader />;
  if (error) return <ErrorComponent />;
  const onTabClick = (key) => {
    setStatus(key);
  };
  const columns = [
    {
      key: '1',
      dataIndex: 'name',
      title: 'City',
      sorter: true
    },
    {
      key: '2',
      dataIndex: 'dishCount',
      title: 'Dishes',
      sorter: true,
      align: 'center'
    },
    {
      key: '3',
      dataIndex: 'eateryCount',
      title: 'Eateries',
      sorter: true,
      align: 'center'
    },
    {
      key: '4',
      dataIndex: '',
      title: 'Action',
      width: '10%',
      align: 'center',
      hidden: user?.role !== 'SUPER_ADMIN',
      render: (value) => {
        const { id } = value;
        return (
          <div
            onClick={(e) => {
              e?.stopPropagation();
            }}
          >
            <Row justify="start">
              <Popover
                content={
                  <Menu>
                    <Menu.Item>
                      <EditCity id={id} status={status} />
                    </Menu.Item>
                    <Menu.Item>
                      <DeleteCity id={id} />
                    </Menu.Item>
                  </Menu>
                }
              >
                <CommonButton
                  type="text"
                  onClick={(e) => {
                    e?.stopPropagation();
                  }}
                >
                  <EllipsisOutlined />
                </CommonButton>
              </Popover>
            </Row>
          </div>
        );
      }
    }
  ]?.filter((item) => !item?.hidden);

  const handleTableChange = (pagination, filters, sorter) => {
    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;
      setSkip(0);
      setLimit(10);

      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('cityNameAsc');
              break;
            case '2':
              setSortBy('dishCountAsc');
              break;
            case '3':
              setSortBy('eateryCountAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('cityNameDesc');
              break;
            case '2':
              setSortBy('dishCountDesc');
              break;
            case '3':
              setSortBy('eateryCountDesc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        default:
          setSortBy('updatedAtDesc');
      }
    }
    if (!_isEmpty(pagination)) {
      const { pageSize, current } = pagination;
      setSkip(pageSize * current - pageSize);
      setLimit(pageSize);
    }
  };
  return (
    <CommonCard className="tabs-card">
      <Tabs
        tabBarExtraContent={<AddCity />}
        onTabClick={onTabClick}
        activeKey={status}
      >
        <Tabs.TabPane tab={<h3 className="mb-0">Live</h3>} key="LIVE">
          <CommonTable
            rowKey={(e) => e?.id}
            columns={columns}
            data={cityData?.getAllCities?.cities}
            onChange={handleTableChange}
            total={cityData?.getAllCities?.count}
            onRow={(record) => {
              return {
                onClick: () => {
                  history?.push(`${ROUTES?.CITY}/${record?.id}`);
                }
              };
            }}
          />
        </Tabs.TabPane>
        {user?.role === 'SUPER_ADMIN' && (
          <Tabs.TabPane tab={<h3 className="mb-0">Draft</h3>} key="DRAFT">
            <CommonTable
              rowKey={(e) => e?.id}
              columns={columns}
              data={cityData?.getAllCities?.cities}
              onChange={handleTableChange}
              total={cityData?.getAllCities?.count}
              onRow={(record) => {
                return {
                  onClick: () => {
                    history?.push(`${ROUTES?.CITY}/${record?.id}`);
                  }
                };
              }}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </CommonCard>
  );
}

export default City;
