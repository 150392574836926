import { useMutation } from '@apollo/client';
import { Col, Popover, Row } from 'antd/lib/index';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import { DELETE_EATERY } from '../graphql/Mutations';
import { GET_ALL_EATERIES } from '../graphql/Queries';

function DeleteEatery({ id, type = 'text', className }) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [deleteEatery, { loading: mutating }] = useMutation(DELETE_EATERY, {
    refetchQueries: [
      {
        query: GET_ALL_EATERIES,
        fetchPolicy: 'cache-and-network'
      }
    ]
  });

  const handleDelete = () => {
    deleteEatery({
      variables: {
        id: id
      }
    })
      ?.then(() => {
        history?.push(ROUTES?.EATERY);
      })
      ?.catch(() => {});
  };
  return (
    <div>
      <Popover
        onClick={(e) => {
          e?.stopPropagation();
        }}
        visible={visible}
        trigger="click"
        content={
          <>
            <p>Are you sure you want to delete this eatery</p>
            <Row>
              <Col>
                <CommonButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    handleDelete();
                  }}
                  loading={mutating}
                >
                  yes
                </CommonButton>
              </Col>
              &nbsp;
              <Col>
                <CommonButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    setVisible(false);
                  }}
                >
                  No
                </CommonButton>
              </Col>
            </Row>
          </>
        }
      >
        <CommonButton
          type={type}
          className={className}
          onClick={(e) => {
            setVisible(true);
            e?.stopPropagation();
          }}
        >
          Delete
        </CommonButton>
      </Popover>
    </div>
  );
}

export default DeleteEatery;
