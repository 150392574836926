import { useQuery } from '@apollo/client';
import { Form, Input, Radio, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonInput from '../../../components/CommonInput';
import CommonModal from '../../../components/CommonModal';
import ImageUpload from '../../../components/imageUpload/ImageUpload';
import SelectComponent from '../../../components/selectComponent/SelectComponent';
import ErrorComponent from '../../../ErrorComponent';
import { DIETARY_AND_DISH_TYPE } from '../../dish-categories/graphql/Queries';

const { required } = formValidatorRules;
function DishForm({
  mutation,
  mutating,
  dishData,
  dishId,
  title,
  buttonLabel,
  dishDataLoading,
  cityId,
  categoryData,
  actionName
}) {
  const [form] = useForm();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const { id: paramsId } = useParams();
  const { data, loading: dataLoading, error } = useQuery(
    DIETARY_AND_DISH_TYPE,
    { variables: { cityId, sortBy: 'updatedAtDesc' } }
  );
  if (dataLoading || dishDataLoading) return <p>loading</p>;
  if (error) return <ErrorComponent />;

  const handleOk = () => {
    form?.submit();
  };
  const handleCancel = () => {
    form?.resetFields();
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const onFinish = (e) => {
    const { name, price, dishType, dishCategory, dietaryType } = e;
    const { id: dishTypeId } = dishType;
    const { id: dietaryTypeId } = dietaryType;
    const { id: categoryId } = dishCategory;

    mutation({
      variables: {
        eateryId: paramsId || ' ',
        id: dishId || '',
        name: name,
        dietaryType: [parseInt(dietaryTypeId, 10)],
        dishType: parseInt(dishTypeId, 10),
        price: parseInt(price, 10),
        dishCategory: parseInt(categoryId, 10),
        imageUrl: imageUrl
      }
    })
      .then(() => {
        setModalVisible(false);
        if (!dishData) history.push(`${ROUTES.EATERY}/edit/${paramsId}`);
        form.resetFields();
      })
      .catch(() => {});
  };
  return (
    <div>
      {actionName === 'editDish' ? (
        <p onClick={showModal} className="gx-text-center pointer">
          {buttonLabel}
        </p>
      ) : (
        <CommonButton type="default" onClick={showModal}>
          {buttonLabel}
        </CommonButton>
      )}

      <CommonModal
        title={title}
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={modalVisible}
        okButtonProps={{ loading: mutating }}
        okText="Save"
      >
        <Form
          onFinish={onFinish}
          initialValues={
            dishData && {
              ...dishData,
              dietaryType: { id: dishData?.dietaryType?.[0]?.id }
            }
          }
          form={form}
          layout="vertical"
          labelCol={{ span: 5 }}
        >
          <Form.Item name="dishImage">
            <ImageUpload
              setImageUrl={setImageUrl}
              existingImageSrc={dishData?.imageUrl}
              imageKey="DISH"
            />
          </Form.Item>
          <Form.Item label="Dish Name" name="name" rules={[required]}>
            <Input.TextArea autoSize />
          </Form.Item>
          <Form.Item label="Dish Price" name="price" rules={[required]}>
            <CommonInput />
          </Form.Item>
          <Form.Item
            label="Dish category"
            name={['dishCategory', 'id']}
            rules={[required]}
          >
            <SelectComponent>
              {categoryData?.dishCategories?.dishCategoriesData.map(
                (category) => {
                  return (
                    <Select.Option value={`${category?.id}`} key={category?.id}>
                      {category?.name}
                    </Select.Option>
                  );
                }
              )}
            </SelectComponent>
          </Form.Item>
          <Form.Item
            label="Dietary Type"
            name={['dietaryType', 'id']}
            rules={[required]}
          >
            <Radio.Group>
              {data?.dietaryTypes?.map((dietary) => {
                return (
                  <Radio value={`${dietary?.id}`} key={dietary.id}>
                    {dietary?.type}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Dish Type"
            name={['dishType', 'id']}
            rules={[required]}
          >
            <Radio.Group>
              {data?.dishTypes.map((dish) => {
                return (
                  <Radio value={`${dish.id}`} key={dish.id}>
                    {dish.type}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Form>
      </CommonModal>
    </div>
  );
}

export default DishForm;
