import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import City from './components/City';
import CityDetails from './components/CityDetails';

function DishCategory() {
  return (
    <div>
      <Switch>
        <Route path={ROUTES.CITY} component={City} exact />
        <Route path={ROUTES.CITY_DETAILS} component={CityDetails} />
      </Switch>
    </div>
  );
}
export default DishCategory;
