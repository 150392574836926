import { gql } from '@apollo/client';

export const CREATE_EATERY = gql`
  mutation createeatery(
    $name: String!
    $contactNo: String!
    $cityId: Int!
    $description: String!
    $isSameAsWeekday: Boolean!
    $weekdayOpen: TIME!
    $weekdayClose: TIME!
    $location: geometry!
    $weekendOpen: TIME!
    $weekendClose: TIME!
    $swiggyAvailable: String
    $zomatoAvailable: String
    $imageUrl: String
    $address: String!
  ) {
    createEatery(
      input: {
        name: $name
        description: $description
        contactNo: $contactNo
        cityId: $cityId
        isSameAsWeekday: $isSameAsWeekday
        weekdayOpen: $weekdayOpen
        weekdayClose: $weekdayClose
        weekendOpen: $weekendOpen
        weekendClose: $weekendClose
        location: $location
        swiggyAvailable: $swiggyAvailable
        zomatoAvailable: $zomatoAvailable
        imageUrl: $imageUrl
        addressLine: $address
      }
    ) {
      message
    }
  }
`;
export default CREATE_EATERY;

export const UPDATE_EATERY = gql`
  mutation updateEatery(
    $id: ID!
    $name: String
    $description: String!
    $contactNo: String
    $cityId: Int
    $weekdayOpen: TIME
    $weekdayClose: TIME
    $isSameAsWeekday: Boolean
    $weekendOpen: TIME
    $weekendClose: TIME
    $location: geometry
    $isActive: Boolean
    $swiggyAvailable: String
    $zomatoAvailable: String
    $imageUrl: String
    $address: String
  ) {
    updateEatery(
      id: $id
      data: {
        name: $name
        description: $description
        contactNo: $contactNo
        cityId: $cityId
        weekdayOpen: $weekdayOpen
        weekdayClose: $weekdayClose
        isSameAsWeekday: $isSameAsWeekday
        weekendOpen: $weekendOpen
        weekendClose: $weekendClose
        location: $location
        isActive: $isActive
        swiggyAvailable: $swiggyAvailable
        zomatoAvailable: $zomatoAvailable
        imageUrl: $imageUrl
        addressLine: $address
      }
    ) {
      message
    }
  }
`;
export const DELETE_EATERY = gql`
  mutation deletEatery($id: [ID!]!) {
    deleteEatery(ids: $id) {
      message
    }
  }
`;
export const CREATE_DISH_FOR_EATERY = gql`
  mutation createDishforEatery(
    $name: String!
    $price: Int!
    $dietaryType: [Int!]!
    $dishType: Int!
    $dishCategory: Int!
    $eateryId: ID!
    $imageUrl: String
  ) {
    createDishforEatery(
      input: {
        eateryId: $eateryId
        name: $name
        price: $price
        dietaryType: $dietaryType
        dishType: $dishType
        dishCategory: $dishCategory
        imageUrl: $imageUrl
      }
    ) {
      message
    }
  }
`;

export const UPDATE_DISH_FOR_EATERY = gql`
  mutation updateDishforEatery(
    $id: ID!
    $name: String
    $dietaryType: [Int]
    $price: Int
    $dishType: Int
    $dishCategory: Int
    $imageUrl: String
  ) {
    updateDishforEatery(
      id: $id
      data: {
        name: $name
        price: $price
        dietaryType: $dietaryType
        dishType: $dishType
        dishCategory: $dishCategory
        imageUrl: $imageUrl
      }
    ) {
      message
    }
  }
`;
export const DELETE_DISH_FOR_EATERY = gql`
  mutation deleteDishforEatery($id: ID!) {
    deleteDishforEatery(id: $id) {
      message
    }
  }
`;
