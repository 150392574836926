import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import Loader from '../../Loader';
import { TOKEN_FROM_REFRESH_TOKEN } from './graphql/Mutations';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(AppContext);
  const history = useHistory();
  const refreshToken = getRefreshToken();
  function successCallback(accessToken, userData, authRefreshToken) {
    initializeAuth(accessToken, userData, authRefreshToken);
  }

  const [refresh, { loading }] = useMutation(TOKEN_FROM_REFRESH_TOKEN, {
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      const accessToken = get(response, 'tokenFromRefreshToken.token');
      const userData = get(response, 'tokenFromRefreshToken.user');
      const authRefreshToken = get(
        response,
        'tokenFromRefreshToken.refreshToken'
      );
      successCallback(accessToken, userData, authRefreshToken);
      history.push(ROUTES.EATERY);
    },
    onError() {
      history.replace(ROUTES.LOGOUT);
    }
  });

  useEffect(() => {
    refresh({
      variables: {
        refreshToken: refreshToken
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader />;

  return null;
};

export default RefreshToken;
