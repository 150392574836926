import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Col, Descriptions, Divider, Image, List, Row } from 'antd';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import defaultImage from '../../../assets/images/noimage.png';
import { ROUTES } from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_DISH_CATEGORIY_BY_ID } from '../graphql/Queries';

function CategoryView() {
  const { id } = useParams();
  const history = useHistory();
  const { data, loading, error } = useQuery(GET_DISH_CATEGORIY_BY_ID, {
    variables: {
      id: id
    },
    fetchPolicy: 'network-only'
  });
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;
  return (
    <CommonCard
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <CommonButton
              type="default"
              onClick={() => {
                history?.goBack();
              }}
            >
              <LeftOutlined />
            </CommonButton>
          </Col>
          <Col>
            <h3 className="mb-0">Category Details</h3>
          </Col>
          <Col>
            <CommonButton
              type="default"
              onClick={() => {
                history?.push(`${ROUTES?.DISH_CATEGORIES}/edit/${id}`);
              }}
            >
              Edit
            </CommonButton>
          </Col>
        </Row>
      }
    >
      <Descriptions bordered layout="vertical" colon={false} column={4}>
        <Descriptions.Item label="Category Name">
          <h4 className="font-ellipsis">{data?.dishCategory?.name}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="City">
          <h4>{data?.dishCategory?.city?.name}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Dietry Type">
          <div>
            {data?.dishCategory.dietaryType.map((dietary) => {
              return <h4 key={dietary?.id}>{dietary?.type}</h4>;
            })}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="Dish Type">
          <h4>{data?.dishCategory?.dishType?.type}</h4>
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions layout="vertical" colon={false} column={3}>
        <Descriptions.Item label="Category Image">
          <Image
            className="eatery-image"
            src={`${data?.dishCategory?.imageUrl || defaultImage} `}
            alt="dish Image"
          />
        </Descriptions.Item>
        <Descriptions.Item label="Category Details">
          <h5>{data?.dishCategory?.details}</h5>
        </Descriptions.Item>
        <Descriptions.Item label="Available at">
          <List itemLayout="vertical">
            {data?.dishCategory?.availableAtEatery?.map((eatery) => {
              return (
                <Link to={`${ROUTES.EATERY}/${eatery.id}`} key={eatery?.id}>
                  <h5>{eatery.name}</h5>
                </Link>
              );
            })}
          </List>
        </Descriptions.Item>
      </Descriptions>
    </CommonCard>
  );
}

export default CategoryView;
