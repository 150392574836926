import { gql } from '@apollo/client';

export const CREATE_DISH_CATEGORIES = gql`
  mutation createDishCategory(
    $name: String!
    $cities: [ID!]!
    $details: String!
    $dietaryType: [Int!]!
    $dishType: Int!
    $image: String
  ) {
    createDishCategory(
      input: {
        name: $name
        cities: $cities
        details: $details
        dietaryType: $dietaryType
        dishType: $dishType
        image: $image
      }
    ) {
      message
    }
  }
`;

export const UPDATE_DISH_CATEGORY = gql`
  mutation updateDishCategroy(
    $id: ID!
    $name: String
    $details: String
    $city: ID!
    $dietaryType: [Int!]
    $dishType: Int
    $image: String
  ) {
    updateDishCategory(
      id: $id
      data: {
        name: $name
        details: $details
        city: $city
        dietaryType: $dietaryType
        dishType: $dishType
        image: $image
      }
    ) {
      message
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation($id: ID!) {
    deleteDishCategory(id: $id) {
      message
    }
  }
`;
export const UPDATE_DISH_CATEGORY_FEATURED_STATUS = gql`
  mutation updateDishCategoryFeaturedStatus($id: ID!) {
    updateDishCategoryFeaturedStatus(id: $id) {
      message
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation generateSignedUrl(
    $fileName: String!
    $fileType: String!
    $key: String!
  ) {
    generateSignedUrl(fileName: $fileName, fileType: $fileType, key: $key) {
      signedUrl
      fileUrl
    }
  }
`;
