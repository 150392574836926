import { useQuery } from '@apollo/client';
import { Form, Select } from 'antd';
import React from 'react';
import { formValidatorRules } from '../../../common/utils';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_ALL_EATERIES } from '../../eatery/graphql/Queries';

const { required } = formValidatorRules;

function SelectEatery({ label, name }) {
  const { data, loading, error } = useQuery(GET_ALL_EATERIES, {
    variables: { sortBy: 'updatedAtDesc' }
  });
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <div>
      <Form.Item name={name} label={label} rules={[required]}>
        <Select>
          {data?.eateries?.eateries.map((eatery) => {
            return (
              <Select.Option value={parseInt(eatery?.id, 10)} key={eatery?.id}>
                {eatery?.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
}

export default SelectEatery;
