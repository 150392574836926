import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import Error404 from '../Error404';
import AdminUser from '../modules/admin-users';
import City from '../modules/city';
import ContactForm from '../modules/contact-form';
import Dishcategories from '../modules/dish-categories';
import Eatery from '../modules/eatery';
import Foodies from '../modules/foodies';
import Report from '../modules/report';
import Review from '../modules/reviews';
import Suggestions from '../modules/suggestion';

const ContentRoutes = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={ROUTES?.MAIN}
          render={() => <Redirect to={ROUTES?.EATERY} />}
        />
        <Route path={ROUTES?.EATERY} component={Eatery} />
        <Route path={ROUTES?.CITY} component={City} />
        <Route path={ROUTES?.DISH_CATEGORIES} component={Dishcategories} />
        <Route path={ROUTES?.FOODIES} component={Foodies} />
        <Route path={ROUTES?.SUGGESTIONS} component={Suggestions} />
        <Route path={ROUTES?.REVIEWS} component={Review} />
        <Route path={ROUTES?.REPORT} component={Report} />
        <Route path={ROUTES?.CONTACT_FORM} component={ContactForm} />
        <Route path={ROUTES?.ADMIN_USERS} component={AdminUser} />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
