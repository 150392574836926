import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Col, Descriptions, Empty, Rate, Row } from 'antd';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { btnClickHandler } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonTag from '../../../components/CommonTag';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { REVIEW } from '../graphql/Queries';
import HideReview from './HideReview';

function ReviewDetails() {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const { id } = useParams();
  const history = useHistory();
  const { data, loading, error } = useQuery(REVIEW, { variables: { id } });
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;
  const { review } = data;

  return (
    <CommonCard
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <CommonButton
              onClick={() => {
                history?.goBack();
              }}
              type="default"
            >
              <LeftOutlined />
            </CommonButton>
          </Col>
          <Col>
            <h3>Review Details</h3>
          </Col>
          {user?.role === 'SUPER_ADMIN' && review?.reviewStatus === 'VISIBLE' && (
            <Col>
              <HideReview />
            </Col>
          )}
        </Row>
      }
    >
      <Descriptions
        className="mb-32"
        column={8}
        layout="vertical"
        bordered
        colon={false}
      >
        <Descriptions.Item label="Review For">
          <h4
            className="tag-pointer"
            onClick={() => {
              // eslint-disable-next-line no-undef
              window?.open(
                btnClickHandler(`eatery-profile/${review?.eatery?.id}/`),
                '_blank'
              );
            }}
          >
            {review?.eatery?.name}
          </h4>
        </Descriptions.Item>
        <Descriptions.Item label="Review By">
          <h3
            className="tag-pointer"
            onClick={() => {
              // eslint-disable-next-line no-undef
              window?.open(
                btnClickHandler(`foodie/${review?.foodie?.id}/`),
                '_blank'
              );
            }}
          >
            {review?.foodie?.name}
          </h3>
        </Descriptions.Item>
        <Descriptions.Item label="Foodie Level">
          <h4>Level {review?.foodie?.level}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Followers">
          <h4>{review?.foodie?.followerCount}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Reviews">
          <h4>{review?.reviewCount}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Suggested Places">
          <h4>{review?.suggestionCount}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Rating">
          <Rate value={review?.rating} />
        </Descriptions.Item>
        <Descriptions.Item label="Suggested Dish">
          {review?.favouriteDish?.length < 1 && '-'}
          {review?.favouriteDish?.map((dish) => {
            return <CommonTag label={dish?.name} key={dish?.id} />;
          })}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={2} layout="vertical" colon={false}>
        {review?.reviewGiven ? (
          <Descriptions.Item label={<h4>Review</h4>}>
            {review?.reviewGiven}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label={<h4>Review</h4>}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Descriptions.Item>
        )}

        {review?.imageUrls.length > 0 ? (
          <Descriptions.Item label={<h4>Image</h4>}>
            {review?.imageUrls?.map((image) => {
              return (
                <div key={image?.id}>
                  <img
                    src={image?.url}
                    alt="Review"
                    className="suggested-image"
                  />
                </div>
              );
            })}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label={<h4>Image</h4>}>
            <Empty
              description={<span>No Image</span>}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
    </CommonCard>
  );
}

export default ReviewDetails;
