import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import FoodiesProfile from './components/FoodiesProfile';
import FoodiesTable from './components/FoodiesTable';

function Foodies() {
  return (
    <div>
      <Switch>
        <Route path={ROUTES.FOODIES} component={FoodiesTable} exact />
        <Route path={ROUTES.FOODIES_PROFILE} component={FoodiesProfile} />
      </Switch>
    </div>
  );
}

export default Foodies;
