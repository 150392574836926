import { Input } from 'antd';
import React from 'react';

function CommonInput({ ...props }) {
  return (
    <div>
      <Input {...props} />
    </div>
  );
}

export default CommonInput;
