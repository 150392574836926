import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Col, Descriptions, Rate, Row } from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonTag from '../../../components/CommonTag';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_SINGLE_REPORTED_REVIEW } from '../graphql/Queries';

function ReportDetails() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_SINGLE_REPORTED_REVIEW, {
    variables: { id }
  });
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  const { singleReportedReview } = data;
  return (
    <CommonCard
      title={
        <Row justify="start" align="middle">
          <Col span={10}>
            <CommonButton
              type="default"
              onClick={() => {
                history?.goBack();
              }}
            >
              <LeftOutlined />
            </CommonButton>
          </Col>
          <Col span={14}>
            <h3>Details</h3>
          </Col>
        </Row>
      }
    >
      <Descriptions
        column={6}
        layout="vertical"
        colon={false}
        bordered
        className="mb-32"
      >
        <Descriptions.Item label="Review For">
          <h4>{singleReportedReview?.eateryName}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Review By">
          <h4>{singleReportedReview?.writtenBy}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Foodie Level">
          <h4>{singleReportedReview?.userLevel}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Followers">
          <h4>{singleReportedReview?.usersFollowerCount}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Reviews">
          <h4>{singleReportedReview?.reviewCount}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Suggested Places">
          <h4>{singleReportedReview?.suggestionCount}</h4>
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        column={5}
        layout="vertical"
        colon={false}
        className="mb-32"
        bordered
      >
        {location.pathname.includes('report') && (
          <>
            <Descriptions.Item label="Reported by">
              <h4>{singleReportedReview?.reportedBy}</h4>
            </Descriptions.Item>
            <Descriptions.Item label="Reason">
              <h4>{singleReportedReview?.reason}</h4>
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="Rating">
          <Rate value={singleReportedReview?.reviewCount} />
        </Descriptions.Item>
        <Descriptions.Item label="Suggested Dish">
          {singleReportedReview?.favouriteDish?.length < 1 && '-'}
          {singleReportedReview?.favouriteDish?.map((dish) => {
            return <CommonTag label={dish?.name} key={dish?.id} />;
          })}
        </Descriptions.Item>
        <Descriptions.Item label="Review">
          {singleReportedReview?.reviewGiven}
        </Descriptions.Item>
      </Descriptions>
    </CommonCard>
  );
}

export default ReportDetails;
