import { useQuery } from '@apollo/client';
import { Tabs, Tooltip, Typography } from 'antd';
import { get } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  ROUTES
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_ALL_SUGGESTIONS } from '../graphql/Queries';

function SuggestionTable() {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState('updatedAtDesc');
  const history = useHistory();
  const [status, setStatus] = useState('PENDING');
  const [suggestionCities, setSuggestionCities] = useState([]);
  const [filterInfo, setFilterInfo] = useState([]);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const { data, loading, error } = useQuery(GET_ALL_SUGGESTIONS, {
    variables: {
      status,
      skip,
      limit,
      sortBy
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: PAGINATION_DEFAULT_PAGE_SIZE,
        total: res?.Suggestions?.count
      };
      setPaginationProp(pagination);
    }
  });
  if (loading && !data) return <Loader />;
  if (error) return <ErrorComponent />;

  const columns = [
    {
      title: 'Eatery Name',
      dataIndex: 'eateryName',
      key: '1',
      sorter: true,
      width: '30%',
      ellipsis: {
        showTitle: false
      },
      render: (eateryName) => (
        <Tooltip placement="topLeft" title={eateryName}>
          {eateryName}
        </Tooltip>
      )
    },
    {
      title: 'City',
      dataIndex: 'eateryCity',
      key: '2',
      filters:
        suggestionCities?.length &&
        suggestionCities?.sort()?.map((city) => {
          return { text: city, value: city };
        }),
      filteredValue: get(filterInfo, 2) || null,
      onFilter: (value, record) => record?.eateryCity.includes(value)
    },
    {
      title: 'Famous for',
      dataIndex: 'knownForDishes',
      key: '3',
      ellipsis: {
        showTitle: false
      },
      render: (dish) => {
        return dish.join(', ');
      }
    },
    {
      title: 'Foodie Name',
      dataIndex: 'foodieName',
      key: '4',
      ellipsis: {
        showTitle: false
      }
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: '5',
      sorter: true,
      render: (createdAt) => {
        return (
          <Typography.Text>{formatDate(createdAt, 'DD/MM/YY')}</Typography.Text>
        );
      }
    }
  ];

  const onTabClick = (key) => {
    setStatus(key);
  };

  data?.Suggestions?.suggestions?.forEach((suggestion) => {
    if (!suggestionCities?.includes(suggestion?.eateryCity)) {
      setSuggestionCities([...suggestionCities, suggestion?.eateryCity]);
    }
  });

  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;
    setFilterInfo(filters);
    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;
      setSkip(0);
      setLimit(10);

      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('eateryNameAsc');
              break;
            case '5':
              setSortBy('createdAtAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('eateryNameDesc');
              break;
            case '5':
              setSortBy('createdAtDesc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;

        default:
          setSortBy('updatedAtDesc');
      }
    }
    if (!_isEmpty(pagination)) {
      setSkip(skipNumber);
      setLimit(pagination.pageSize);
      setPaginationProp({ ...paginationProp, ...pagination });
    }
  };
  return (
    <CommonCard className="tabs-card">
      <Tabs onTabClick={onTabClick} activeKey={status}>
        <Tabs.TabPane tab="Pending" key="PENDING">
          <CommonTable
            rowKey={(e) => e?.id}
            columns={columns}
            data={data?.Suggestions?.suggestions}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            onRow={(record) => {
              return {
                onClick: () => {
                  history?.push(`${ROUTES?.SUGGESTIONS}/${record?.id}`);
                }
              };
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Accepted" key="ACCEPTED">
          <CommonTable
            rowKey={(e) => e?.id}
            columns={columns}
            data={data?.Suggestions?.suggestions}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            scroll={{ x: 1000 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  history?.push(`${ROUTES?.SUGGESTIONS}/${record?.id}`);
                }
              };
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Rejected" key="REJECTED">
          <CommonTable
            rowKey={(e) => e?.id}
            columns={columns}
            data={data?.Suggestions?.suggestions}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            onRow={(record) => {
              return {
                onClick: () => {
                  history?.push(`${ROUTES?.SUGGESTIONS}/${record?.id}`);
                }
              };
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </CommonCard>
  );
}

export default SuggestionTable;
