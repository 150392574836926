import { Layout, Menu } from 'antd';
import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import Logo from '../../../assets/logo.png';
import { MODULES, ROUTES } from '../../../common/constants';

const { Sider } = Layout;

function Sidebar({ location: { pathname }, history }) {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();

  const onMenuSelect = (e) => {
    history?.push(e?.key);
  };

  return (
    <Sider trigger={null}>
      <div className="gx-layout-sider-header">
        <div
          className="logo-img"
          onClick={() => {
            history?.push(ROUTES?.MAIN);
          }}
        >
          <img width={140} src={Logo} alt="logo" />
        </div>
      </div>
      <div className="gx-sidebar-content">
        <Menu
          mode="inline"
          selectedKeys={[`/${pathname.split('/')[1]}`]}
          defaultSelectedKeys={[ROUTES?.EATERY]}
          onSelect={onMenuSelect}
          className="sidebar-menu"
        >
          <Menu.Item key={ROUTES?.EATERY} className="d-flex align-items-center">
            <Link to={ROUTES?.EATERY}>
              <span className="nav-item">{MODULES?.EATERY}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={ROUTES?.CITY} className="d-flex align-items-center">
            <Link to={ROUTES?.CITY}>
              <span className="nav-item">{MODULES?.CITY}</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key={ROUTES?.FOODIES}
            className="d-flex align-items-center"
          >
            <Link to={ROUTES?.FOODIES}>
              <span className="nav-item">{MODULES?.FOODIES}</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key={ROUTES?.SUGGESTIONS}
            className="d-flex align-items-center"
          >
            <Link to={ROUTES?.SUGGESTIONS}>
              <span className="nav-item">{MODULES?.SUGGESTIONS}</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key={ROUTES?.REVIEWS}
            className="d-flex align-items-center"
          >
            <Link to={ROUTES?.REVIEWS}>
              <span className="nav-item">{MODULES?.REVIEWS}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={ROUTES?.REPORT} className="d-flex align-items-center">
            <Link to={ROUTES?.REPORT}>
              <span className="nav-item">{MODULES?.REPORT}</span>
            </Link>
          </Menu.Item>
          {user.role === 'SUPER_ADMIN' && (
            <>
              <Menu.Item
                key={ROUTES?.CONTACT_FORM}
                className="d-flex align-items-center"
              >
                <Link to={ROUTES?.CONTACT_FORM}>
                  <span className="nav-item">{MODULES?.CONTACT_FORM}</span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={ROUTES?.ADMIN_USERS}
                className="d-flex align-items-center"
              >
                <Link to={ROUTES?.ADMIN_USERS}>
                  <span className="nav-item">{MODULES?.ADMIN_USERS}</span>
                </Link>
              </Menu.Item>
            </>
          )}
        </Menu>
      </div>
    </Sider>
  );
}

export default withRouter(Sidebar);
