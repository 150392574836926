import { Select } from 'antd';
import React from 'react';

function SelectComponent({ children, placeHolder, ...props }) {
  return (
    <Select placeholder={placeHolder} {...props}>
      {children}
    </Select>
  );
}

export default SelectComponent;
