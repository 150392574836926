import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { UPDATE_DISH_CATEGORY } from '../graphql/Mutations';
import {
  GET_ALL_DISH_CATEGORIES,
  GET_DISH_CATEGORIY_BY_ID
} from '../graphql/Queries';
import CategoryForm from './CategoryForm';

function CategoryEdit() {
  const { id } = useParams();
  const [updateDishCategory] = useMutation(UPDATE_DISH_CATEGORY, {
    refetchQueries: [
      { query: GET_ALL_DISH_CATEGORIES, variables: { cityId: id } }
    ]
  });

  const { data, loading, error } = useQuery(GET_DISH_CATEGORIY_BY_ID, {
    variables: {
      id: parseInt(id, 10)
    },
    fetchPolicy: 'network-only'
  });
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <div>
      <CategoryForm
        categoryData={data?.dishCategory}
        mutation={updateDishCategory}
        id={id}
      />
    </div>
  );
}

export default CategoryEdit;
