import {
  EllipsisOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Form, Menu, message, Popover, Row, Tooltip } from 'antd';
import { get } from 'lodash';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  ROUTES
} from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { UPDATE_DISH_CATEGORY_FEATURED_STATUS } from '../graphql/Mutations';
import { GET_ALL_DISH_CATEGORIES } from '../graphql/Queries';
import DeleteCategory from './DeleteCategory';

function CategoriesTable() {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const { id } = useParams();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState('updatedAtDesc');
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [categoryType, setCategoryType] = useState([]);
  const [filterInfo, setFilterInfo] = useState([]);
  const [categoryDishType, setCategoryDishType] = useState([]);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const [loading, setLoading] = useState(true);
  const [updateDishCategoryFeaturedStatus] = useMutation(
    UPDATE_DISH_CATEGORY_FEATURED_STATUS
  );
  const { data, loading: queryLoading, error } = useQuery(
    GET_ALL_DISH_CATEGORIES,
    {
      variables: { skip, limit, sortBy, cityId: id },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: PAGINATION_DEFAULT_PAGE_SIZE,
          total: res?.dishCategories?.count
        };
        setPaginationProp(pagination);
      }
    }
  );

  useEffect(() => {
    if (_has(data, 'dishCategories.dishCategoriesData') && !queryLoading) {
      const categoriesData = [];
      data?.dishCategories?.dishCategoriesData?.forEach((categoryData) => {
        categoriesData?.push({
          ...categoryData,
          dietaryType: categoryData?.dietaryType?.map((type) => {
            return `${type?.type}`;
          })
        });
      });
      setCategories(categoriesData);
    }
    setLoading(false);
  }, [data, queryLoading]);

  const updateFeature = (categoryId) => {
    try {
      updateDishCategoryFeaturedStatus({
        variables: { id: categoryId },
        refetchQueries: [
          { query: GET_ALL_DISH_CATEGORIES, variables: { cityId: id } }
        ]
      });
    } catch (err) {
      message?.error('Something went wrong');
    }
  };
  const FeaturePopover = ({ featured, record }) => {
    return (
      <Form>
        <Form.Item name="featured" className="mb-0">
          <Popover
            onClick={(e) => {
              e?.stopPropagation();
            }}
            trigger="click"
            content={
              <>
                <p>
                  Are you sure you want to {featured ? 'unfeature' : 'feature'}{' '}
                  this category
                </p>
                <CommonButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    updateFeature(record?.id);
                  }}
                >
                  Yes
                </CommonButton>
              </>
            }
          >
            <CommonButton type="text">
              {featured ? <StarFilled /> : <StarOutlined />}
            </CommonButton>
          </Popover>
        </Form.Item>
      </Form>
    );
  };
  const columns = [
    {
      title: 'Category Name',
      dataIndex: 'name',
      key: '1',
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      )
    },
    {
      title: 'Type',
      dataIndex: ['dishType', 'type'],
      key: '2',
      filters:
        categoryDishType?.length &&
        categoryDishType?.sort()?.map((type) => {
          return { text: type, value: type };
        }),
      filteredValue: get(filterInfo, 2) || null,
      onFilter: (value, record) => record?.dishType?.type?.includes(value)
    },
    {
      title: 'Diet',
      dataIndex: 'dietaryType',
      key: '3',
      render: (diet) => {
        return diet?.join(', ');
      },
      filters:
        categoryType?.length &&
        categoryType?.sort()?.map((type) => {
          return { text: type, value: type };
        }),
      filteredValue: get(filterInfo, 3) || null,
      onFilter: (value, record) => {
        return record?.dietaryType?.some((dietary) => dietary?.includes(value));
      }
    },
    {
      title: 'Available At',
      dataIndex: 'availableAtEateryCount',
      key: '4',
      sorter: true,
      align: 'center'
    },

    {
      title: 'Featured',
      dataIndex: 'featured',
      key: '5',
      align: 'center',
      render: (featured, record) => {
        return <FeaturePopover featured={featured} record={record} />;
      }
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '6',
      render: (_, value) => {
        return (
          <Row justify="start" align="middle">
            <Popover
              content={
                <Menu>
                  <Menu.Item>
                    <CommonButton
                      type="text"
                      onClick={(e) => {
                        e?.stopPropagation();
                        history?.push(
                          `${ROUTES?.DISH_CATEGORIES}/edit/${value?.id}`
                        );
                      }}
                    >
                      Edit
                    </CommonButton>
                  </Menu.Item>
                  {user?.role === 'SUPER_ADMIN' && (
                    <Menu.Item>
                      <DeleteCategory id={value?.id} />
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <CommonButton
                type="text"
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              >
                <EllipsisOutlined />
              </CommonButton>
            </Popover>
          </Row>
        );
      }
    }
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;
    setFilterInfo(filters);

    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;
      setSkip(0);
      setLimit(10);

      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('nameAsc');
              break;
            case '4':
              setSortBy('availableAtEateryCountAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('nameDesc');
              break;
            case '4':
              setSortBy('availableAtEateryCountDesc');
              break;

            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;

        default:
          setSortBy('updatedAtDesc');
      }
    }
    if (!_isEmpty(pagination)) {
      setSkip(skipNumber);
      setLimit(pagination?.pageSize);
      setPaginationProp({ ...paginationProp, ...pagination });
    }
  };
  data?.dishCategories?.dishCategoriesData?.forEach((category) => {
    if (!categoryDishType?.includes(category?.dishType?.type)) {
      setCategoryDishType([...categoryDishType, category?.dishType?.type]);
    } else if (!categoryType?.includes(category?.dietaryType?.[0]?.type)) {
      setCategoryType([...categoryType, category?.dietaryType?.[0]?.type]);
    }
  });

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;
  return (
    <CommonCard
      className="table-card"
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <h3 className="mb-0">Local Delicacies</h3>
          </Col>
          <Col>
            <CommonButton
              type="primary"
              className="btn-2"
              onClick={() => {
                history?.push(ROUTES?.CATEGORIES_ADD);
              }}
            >
              <PlusOutlined /> Add category
            </CommonButton>
          </Col>
        </Row>
      }
    >
      <CommonTable
        columns={columns}
        rowKey={(e) => e?.id}
        data={categories}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        onRow={(record) => {
          return {
            onClick: () => {
              history?.push(`${ROUTES?.DISH_CATEGORIES}/${record?.id}`);
            }
          };
        }}
      />
    </CommonCard>
  );
}

export default CategoriesTable;
