import { useMutation } from '@apollo/client';
import { Col, Form, Radio, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonModal from '../../../components/CommonModal';
import { UPDATE_SUGGESTION_STATUS } from '../graphql/Mutations';
import { GET_ALL_SUGGESTIONS } from '../graphql/Queries';

const { required } = formValidatorRules;

function DeclineModal() {
  const history = useHistory();
  const { id } = useParams();
  const [form] = useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [updateSuggestionStatus, { loading: mutating }] = useMutation(
    UPDATE_SUGGESTION_STATUS,
    {
      refetchQueries: [
        {
          query: GET_ALL_SUGGESTIONS,
          variables: { status: 'PENDING' },
          fetchPolicy: 'cache-and-network'
        }
      ]
    }
  );
  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };
  const onFinish = (e) => {
    const { rejectionMessage } = e;
    updateSuggestionStatus({
      variables: { id, isRejected: true, rejectionMessage }
    })
      .then(() => {
        setModalVisible(false);
        history.push(ROUTES.SUGGESTIONS);
      })
      .catch(() => {});
  };
  return (
    <div>
      <CommonButton type="primary" onClick={showModal}>
        Reject
      </CommonButton>
      <CommonModal
        title="Reason to Decline"
        okText="Decline"
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={modalVisible}
        okButtonProps={{ loading: mutating }}
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item
            className="mb-0"
            name="rejectionMessage"
            rules={[required]}
          >
            <Radio.Group className="width-full">
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Radio value="Eatery Already Listed">
                    Eatery Already Listed
                  </Radio>
                </Col>
                <Col span={24}>
                  <Radio value="Insuffecient Details">
                    Insuffecient Details
                  </Radio>
                </Col>
                <Col span={24}>
                  <Radio value="Unable to contact eatery">
                    Unable to contact eatery
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
        </Form>
      </CommonModal>
    </div>
  );
}

export default DeclineModal;
