import { gql } from '@apollo/client';

export const DELETE_REPORTS = gql`
  mutation deleteReports($ids: [ID!]!) {
    deleteReports(ids: $ids) {
      message
    }
  }
`;
export default DELETE_REPORTS;
