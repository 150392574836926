import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Col, Descriptions, Row } from 'antd';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { btnClickHandler } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import CategoriesTable from '../../dish-categories/components/CategoriesTable';
import { CITY } from '../graphql/Queries';
import DeleteCity from './DeleteCity';
import EditCity from './EditCity';

function CityDetails() {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const { id } = useParams();
  const history = useHistory();

  const { data, loading, error } = useQuery(CITY, {
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  const { getCity } = data;
  return (
    <div>
      <CommonCard
        className="mb-32"
        title={
          <Row justify="space-between" align="middle">
            <Col>
              <CommonButton
                onClick={() => {
                  history?.goBack();
                }}
                type="default"
              >
                <LeftOutlined />
              </CommonButton>
            </Col>
            <Col>
              <h3 className="mb-0">Details</h3>
            </Col>
            <Col>
              {user?.role === 'SUPER_ADMIN' && (
                <Row gutter={10}>
                  <Col>
                    <DeleteCity id={id} />
                  </Col>
                  <Col>
                    <EditCity id={id} status={getCity?.status} />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        }
      >
        <Descriptions layout="vertical" colon={false} column={8}>
          <Descriptions.Item label="Name">
            <h4
              className="tag-pointer"
              onClick={() => {
                // eslint-disable-next-line no-undef
                window?.open(
                  btnClickHandler(`city/${getCity?.name}/${getCity?.id}/`),
                  '_blank'
                );
              }}
            >
              {getCity?.name}
            </h4>
          </Descriptions.Item>
          <Descriptions.Item label="Eateries">
            <h4>{getCity?.eateryCount}</h4>
          </Descriptions.Item>
          <Descriptions.Item label="Local Delicacies">
            <h4>{getCity?.dishCount}</h4>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <h4>{getCity?.status}</h4>
          </Descriptions.Item>
          <Descriptions.Item label="Description">
            <h4>{getCity?.description}</h4>
          </Descriptions.Item>
        </Descriptions>
      </CommonCard>
      <CategoriesTable />
    </div>
  );
}

export default CityDetails;
