import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import SuggestionDetail from './components/SuggestionDetails';
import SuggestionTable from './components/SuggestionTable';

function Suggestion() {
  return (
    <div>
      <Switch>
        <Route path={ROUTES.SUGGESTIONS} component={SuggestionTable} exact />
        <Route
          path={ROUTES.SUGGESTION_DETAILS}
          component={SuggestionDetail}
          exact
        />
      </Switch>
    </div>
  );
}

export default Suggestion;
