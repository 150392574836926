import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Col, Descriptions, Row } from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_SINGLE_REPORTED_EATERY } from '../graphql/Queries';

function EateryReportDetails() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_SINGLE_REPORTED_EATERY, {
    variables: { id }
  });
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  const { singleReportedEatery } = data;
  return (
    <CommonCard
      title={
        <Row justify="start" align="middle">
          <Col span={10}>
            <CommonButton
              type="default"
              onClick={() => {
                history?.goBack();
              }}
            >
              <LeftOutlined />
            </CommonButton>
          </Col>
          <Col span={14}>
            <h3>Details</h3>
          </Col>
        </Row>
      }
    >
      <Descriptions
        column={5}
        layout="vertical"
        colon={false}
        bordered
        className="mb-32"
      >
        <Descriptions.Item label="Eatery Name">
          <h4>{singleReportedEatery?.eateryName}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Contact Number">
          <h4>{singleReportedEatery?.contactNo}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="City">
          <h4>{singleReportedEatery?.city?.name}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Address">
          <h4>{singleReportedEatery?.addressLine}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Ratings">
          <h4>{singleReportedEatery?.rating}</h4>
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        column={5}
        layout="vertical"
        colon={false}
        className="mb-32"
        bordered
      >
        {location?.pathname?.includes(ROUTES?.REPORT) && (
          <>
            <Descriptions.Item label="Reported by">
              <h4>{singleReportedEatery?.reportedBy}</h4>
            </Descriptions.Item>
            <Descriptions.Item label="Reason">
              <h4>{singleReportedEatery?.reason}</h4>
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="Description" span={24}>
          {singleReportedEatery?.description}
        </Descriptions.Item>
      </Descriptions>
    </CommonCard>
  );
}

export default EateryReportDetails;
