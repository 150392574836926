import { gql } from '@apollo/client';

export const GET_ALL_DISH_CATEGORIES = gql`
  query dishCategories(
    $skip: Int
    $limit: Int
    $sortBy: DishCategorySortBy
    $cityId: ID!
  ) {
    dishCategories(
      filter: { sortBy: $sortBy, skip: $skip, limit: $limit }
      cityId: $cityId
    ) {
      count
      dishCategoriesData {
        id
        name
        featured
        dietaryType {
          type
        }
        dishType {
          type
          id
        }
        createdAt
        availableAtEateryCount
      }
    }
  }
`;
export const GET_DISH_CATEGORIY_BY_ID = gql`
  query getdishcategory($id: ID!) {
    dishCategory(id: $id) {
      id
      name
      details
      city {
        id
        name
      }
      dietaryType {
        id
        type
      }
      dishType {
        id
        type
      }
      imageUrl
      createdAt
      availableAtEatery {
        id
        name
      }
      availableAtEateryCount
    }
  }
`;

export const DIETARY_AND_DISH_TYPE = gql`
  query dishData {
    dishTypes {
      id
      type
    }
    dietaryTypes {
      id
      type
    }
    getAllCities(filter: { sortBy: updatedAtDesc }) {
      cities {
        id
        name
      }
    }
  }
`;
