import { gql } from '@apollo/client';

export const GET_FOODIES = gql`
  query users(
    $sortBy: UserSortBy
    $status: UserStatus!
    $skip: Int
    $limit: Int
  ) {
    users(
      filter: { sortBy: $sortBy, skip: $skip, limit: $limit }
      status: $status
    ) {
      count
      users {
        id
        firstName
        lastName
        status
        level
        createdAt
        userReviewCount
        usersSuggestionsCount
        city {
          id
          name
        }
        status
      }
    }
  }
`;

export const GET_FOODIE_BY_ID = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id, status: ACCEPTED) {
      isBlock
      id
      firstName
      lastName
      message
      status
      city {
        id
        name
      }
      level
      usersFollowerCount
      userReviewCount
      usersReviews {
        id
        rating
        experience
        userImage
        suggestedDishes {
          id
          name
        }
        eatery {
          name
          id
          city {
            id
            name
          }
        }
      }
      usersSuggestionsCount
      usersSuggestion {
        id
        eateryName
        eateryPhoneNo
        eateryCity
        knownForDishes {
          name
        }
        imageUrls {
          id
          url
        }
        location {
          longitude
          latitude
        }
      }
    }
  }
`;
