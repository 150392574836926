import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Col, Form, Input, Row, Spin } from 'antd';
import React from 'react';
import Logo from '../../assets/logo.png';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonButton from '../../components/CommonButton';
import { CHANGE_PASSWORD } from './graphql/Mutations';

const { required } = formValidatorRules;

const ChangePassword = (props) => {
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD);
  const { history } = props;

  const onFinish = (e) => {
    const { password } = e;
    changePassword({ variables: { password } })
      .then(() => {
        history.push(ROUTES.LOGOUT);
      })
      .catch(() => {});
  };
  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="gx-mb-4">
          <h2>Change Password</h2>
          <p>Enter a new password for your account</p>
        </div>
        <Spin spinning={false}>
          <Form
            name="change-password"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item name="password" rules={[required]}>
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                required,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value !== getFieldValue('password')) {
                      return Promise.reject(
                        new Error('Passwords do not match')
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Retype password"
              />
            </Form.Item>
            <Row gutter={16}>
              <Col>
                <Form.Item className="mr-2">
                  <CommonButton
                    loading={loading}
                    type="primary"
                    className="mr-2"
                    htmlType="submit"
                  >
                    Change Password
                  </CommonButton>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <CommonButton
                    onClick={() => {
                      history.push(ROUTES.LOGIN);
                    }}
                  >
                    Cancel
                  </CommonButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ChangePassword;
