import Modal from 'antd/lib/modal/Modal';
import React from 'react';

function CommonModal({
  children,
  centered,
  cancelText,
  closable,
  okText,
  title,
  visible,
  handleOk,
  handleCancel,
  onOkFunction,
  ...props
}) {
  return (
    <div>
      <Modal
        closable={closable}
        cancelText={cancelText}
        okText={okText}
        centered={centered}
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        {...props}
      >
        {children}
      </Modal>
    </div>
  );
}

export default CommonModal;
