import { useMutation } from '@apollo/client';
import { Col, Popover, Row } from 'antd/lib/index';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import { DELETE_CITY } from '../graphql/Mutations';
import { GET_ALL_CITIES } from '../graphql/Queries';

function DeleteCity({ id }) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [deleteCity, { loading: mutating }] = useMutation(DELETE_CITY, {
    refetchQueries: [
      {
        query: GET_ALL_CITIES,
        fetchPolicy: 'cache-and-network'
      }
    ]
  });

  const handleDelete = () => {
    deleteCity({
      variables: {
        id: id,
        force: true
      }
    })
      ?.then(() => {
        history?.push(ROUTES?.CITY);
      })
      ?.catch((error) => {
        return error;
      });
  };
  return (
    <div>
      <Popover
        onClick={(e) => {
          e?.stopPropagation();
        }}
        visible={visible}
        trigger="click"
        content={
          <>
            <p>Are you sure you want to delete this City</p>
            <Row>
              <Col>
                <CommonButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    handleDelete();
                  }}
                  loading={mutating}
                >
                  yes
                </CommonButton>
              </Col>
              &nbsp;
              <Col>
                <CommonButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    setVisible(false);
                  }}
                >
                  No
                </CommonButton>
              </Col>
            </Row>
          </>
        }
      >
        <CommonButton
          type="default"
          onClick={(e) => {
            e?.stopPropagation();
            setVisible(true);
          }}
        >
          Delete
        </CommonButton>
      </Popover>
    </div>
  );
}

export default DeleteCity;
