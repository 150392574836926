import { LeftOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Col, Descriptions, Empty, Row, Space, Tooltip } from 'antd';
import { get } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import defaultImage from '../../../assets/images/noimage.png';
import { ROLES, ROUTES } from '../../../common/constants';
import { btnClickHandler, formatTime } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import CommonTag from '../../../components/CommonTag';
import GoogleMap from '../../../components/googleMap';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_EATERY_BY_ID } from '../graphql/Queries';
import AddDish from './AddDish';
import DeleteEatery from './DeleteEatery';

function EateryDetails() {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser() || {};
  const [isSameAsWeekday, setIsSameAsWeekday] = useState(false);
  const [sortBy, setSortBy] = useState('updatedAtDesc');
  const { id } = useParams();
  const history = useHistory();

  // for filter by type
  const [dishTypes, setDishTypes] = useState([]);
  const [dishDiets, setDishDiets] = useState([]);
  const [filterInfo, setFilterInfo] = useState('');

  const { data, loading, error } = useQuery(GET_EATERY_BY_ID, {
    variables: {
      id: id,
      sortBy
    },
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    if (data?.singleEatery?.eateryTiming?.isSameAsWeekday) {
      setIsSameAsWeekday(true);
    }
  }, [isSameAsWeekday, data]);

  useEffect(() => {
    const eateryData = data?.singleEatery;
    eateryData?.menu?.forEach((eatery) => {
      if (!dishTypes?.includes(eatery?.dishType?.type)) {
        setDishTypes([...dishTypes, eatery?.dishType?.type]);
      }
    });
    eateryData?.menu?.forEach((eatery) => {
      eatery?.dietaryType?.forEach((dietaryTypeObj) => {
        if (!dishDiets?.includes(dietaryTypeObj?.type)) {
          setDishDiets([...dishDiets, dietaryTypeObj?.type]);
        }
      });
    });
  }, [data, dishDiets, dishTypes]);

  if (loading && !data) return <Loader />;
  if (error) return <ErrorComponent />;
  const { singleEatery } = data;
  const columns = [
    {
      title: 'Dish name',
      dataIndex: 'dishName',
      key: '1',
      width: '30%',
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      render: (dishName) => (
        <Tooltip placement="topLeft" title={dishName}>
          {dishName}
        </Tooltip>
      )
    },
    {
      title: 'Type',
      dataIndex: ['dishType', 'type'],
      key: '2',
      align: 'center',
      filters:
        dishTypes?.length &&
        dishTypes?.sort()?.map((city) => {
          return { text: city, value: city };
        }),
      filteredValue: get(filterInfo, 2) || null,
      onFilter: (value, record) => record?.dishType?.type?.includes(value)
    },
    {
      title: 'Diet',
      dataIndex: ['dietaryType'],
      key: '3',
      align: 'center',
      render: (dietaryTypeObj) => {
        return dietaryTypeObj?.map((dietary) => <div>{dietary?.type}</div>);
      },
      filters:
        dishDiets?.length &&
        dishDiets?.sort()?.map((city) => {
          return { text: city, value: city };
        }),
      filteredValue: get(filterInfo, 3) || null,
      onFilter: (value, record) => {
        return record?.dietaryType?.some((dietary) =>
          dietary?.type?.includes(value)
        );
      }
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: '4',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Suggested by',
      dataIndex: 'suggestedBy',
      key: '5',
      align: 'center'
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: '6',
      align: 'center',
      render: (value) => {
        if (value) {
          return <StarFilled />;
        }
        return <StarOutlined />;
      }
    }
  ];

  const handleTableChange = (_, filters, sorter) => {
    setFilterInfo(filters);
    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;

      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('dishNameAsc');
              break;
            case '4':
              setSortBy('priceAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('dishNameDesc');
              break;
            case '4':
              setSortBy('priceDesc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        default:
          setSortBy('updatedAtDesc');
      }
    }
  };

  const clickHandler = (url) => {
    // eslint-disable-next-line no-undef
    window?.open(url, '_blank');
  };

  return (
    <div>
      <CommonCard
        className="mb-32"
        title={
          <Row justify="space-between" align="middle">
            <Col>
              <CommonButton
                type="default"
                onClick={() => {
                  history?.goBack();
                }}
              >
                <LeftOutlined />
              </CommonButton>
            </Col>
            <Col>
              <h3 className="mb-0">Details</h3>
            </Col>
            <Col>
              <Row gutter={10}>
                {user?.role === ROLES?.SUPER_ADMIN && (
                  <Col>
                    <DeleteEatery id={id} type="default" />
                  </Col>
                )}
                <Col>
                  <CommonButton
                    type="primary"
                    onClick={() => {
                      history?.push(`${ROUTES?.EATERY}/edit/${id}`);
                    }}
                  >
                    Edit
                  </CommonButton>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      >
        <Row justify="space-between" className="mb-32">
          <Col span={16}>
            <Descriptions layout="vertical" colon={false} column={4}>
              <Descriptions.Item label="Name">
                <h4
                  className="tag-pointer"
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    window?.open(
                      btnClickHandler(`eatery-profile/${id}/`),
                      '_blank'
                    );
                  }}
                >
                  {singleEatery?.name}
                </h4>
              </Descriptions.Item>
              <Descriptions.Item label="Contact Number">
                <h4>{singleEatery?.contactNo}</h4>
              </Descriptions.Item>
              <Descriptions.Item label="Rating">
                <h4>{singleEatery?.rating}</h4>
              </Descriptions.Item>
              <Descriptions.Item label="City">
                <h4
                  className="tag-pointer"
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    window?.open(
                      btnClickHandler(
                        `city/${singleEatery?.city?.name}/${singleEatery?.city?.id}/`
                      ),
                      '_blank'
                    );
                  }}
                >
                  {singleEatery?.city?.name}
                </h4>
              </Descriptions.Item>
            </Descriptions>
            {singleEatery?.famousFor?.length > 0 ? (
              <Descriptions layout="vertical" colon={false} column={2}>
                <Descriptions.Item label="Description">
                  <h5>{singleEatery?.description || '-'}</h5>
                </Descriptions.Item>
                <Descriptions.Item label="Known for">
                  <Row gutter={5}>
                    {singleEatery?.famousFor?.map((dish) => {
                      return (
                        <Col key={dish?.id}>
                          <CommonTag label={dish?.dishName} />
                        </Col>
                      );
                    })}
                  </Row>
                </Descriptions.Item>
              </Descriptions>
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Col>
          <Col span={8} className="gx-text-right">
            <img
              src={singleEatery?.imageUrl || defaultImage}
              alt="eatery"
              className="eatery-image"
            />
          </Col>
        </Row>
        <Row justify="space-between" align="top">
          <Col span={10}>
            <h3 className="mb-16">Time</h3>
            <Descriptions
              className="mb-8"
              layout="vertical"
              colon={false}
              column={2}
            >
              <Descriptions.Item label="WeekDays">
                <h5>
                  <Space>
                    {formatTime(singleEatery?.eateryTiming?.weekdayOpen)} to
                    {formatTime(singleEatery?.eateryTiming?.weekdayClose)}
                  </Space>
                </h5>
              </Descriptions.Item>
              <Descriptions.Item label="Weekends">
                <h5>
                  <Space>
                    {formatTime(singleEatery?.eateryTiming.weekendOpen)} to
                    {formatTime(singleEatery?.eateryTiming.weekendClose)}
                  </Space>
                </h5>
              </Descriptions.Item>
            </Descriptions>
            <Space>
              {singleEatery?.zomatoAvailable?.length > 0 && (
                <CommonButton
                  type="primary"
                  onClick={() => clickHandler(singleEatery?.zomatoAvailable)}
                >
                  Go To Zomato
                </CommonButton>
              )}
              {singleEatery?.swiggyAvailable?.length > 0 && (
                <CommonButton
                  type="primary"
                  className="btn-1"
                  onClick={() => clickHandler(singleEatery?.swiggyAvailable)}
                >
                  Go To Swiggy
                </CommonButton>
              )}
            </Space>
          </Col>
          <Col span={12}>
            <GoogleMap initialPosition={singleEatery?.location} />
          </Col>
        </Row>
      </CommonCard>
      <CommonCard
        className="table-card"
        title={
          <Row justify="space-between" align="middle">
            <Col>
              <h3 className="mb-0">Menu</h3>
            </Col>
            <Col>
              <AddDish cityId={singleEatery?.city?.id} />
            </Col>
          </Row>
        }
      >
        <CommonTable
          rowKey={(e) => e?.id}
          columns={columns}
          scroll={{ x: 1000 }}
          data={singleEatery?.menu}
          tableRowIsClickable={false}
          onChange={handleTableChange}
        />
      </CommonCard>
    </div>
  );
}

export default EateryDetails;
