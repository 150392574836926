import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Col, Descriptions, Empty, Row } from 'antd';
import _get from 'lodash/get';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonTag from '../../../components/CommonTag';
import GoogleMap from '../../../components/googleMap';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_SUGGESTION_BY_ID } from '../graphql/Queries';
import AssignToModal from './AssignToModal';
import DeclineModal from './DeclineModal';

function SuggestionDetails() {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { data, loading, error } = useQuery(GET_SUGGESTION_BY_ID, {
    variables: {
      id: id
    },
    fetchPolicy: 'network-only'
  });
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;
  return (
    <CommonCard
      title={
        <Row justify="space-between" align="middle">
          <Col span={12}>
            <Row justify="space-between" align="middle">
              <Col>
                <CommonButton
                  onClick={() => {
                    history?.goBack();
                  }}
                  type="default"
                >
                  <LeftOutlined />
                </CommonButton>
              </Col>
              <Col>
                <h3>Suggested Eatery</h3>
              </Col>
            </Row>
          </Col>
          {data?.Suggestion?.suggestionStatus === 'PENDING' && (
            <Row gutter={4}>
              <Col>
                <AssignToModal
                  defaultVisible={_get(location, 'state.visible', false)}
                />
              </Col>
              <Col>
                <DeclineModal />
              </Col>
            </Row>
          )}
        </Row>
      }
    >
      <Descriptions
        className="mb-32"
        colon={false}
        layout="vertical"
        column={5}
      >
        <Descriptions.Item label="Eatery name">
          <h4>{data.Suggestion?.eateryName}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Contact Number">
          <h4>{data.Suggestion?.eateryContactNo}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="City">
          <h4>{data.Suggestion?.eateryCity}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Suggested By">
          <h4>{data.Suggestion?.foodieName}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Known for">
          <Row gutter={10}>
            {data?.Suggestion?.knownForDishes?.map((dish) => {
              return (
                <Col key={dish?.id}>
                  <CommonTag label={dish?.name} />
                </Col>
              );
            })}
          </Row>
        </Descriptions.Item>
      </Descriptions>
      <Row justify="space-between">
        <Col span={11}>
          <h3 className="mb-8">Images</h3>
          {data?.Suggestion?.imageUrls?.map((image) => {
            return (
              <Col key={image?.id}>
                <img
                  src={image?.url}
                  alt="suggestion"
                  className="suggested-eatery"
                />
              </Col>
            );
          })}
          {data?.Suggestion?.imageUrls?.length === 0 && (
            <Row>
              <Col span={8}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            </Row>
          )}
        </Col>
        <Col span={11}>
          <h3 className="mb-8">Location</h3>
          <GoogleMap initialPosition={data?.Suggestion?.location} />
        </Col>
      </Row>
    </CommonCard>
  );
}

export default SuggestionDetails;
