import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Form, Input, Spin } from 'antd';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Logo from '../../assets/logo.png';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonButton from '../../components/CommonButton';
import { LOGIN } from './graphql/Mutations';

const { required } = formValidatorRules;

const Login = () => {
  const [login] = useMutation(LOGIN);
  const { initializeAuth } = useContext(AppContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  function successCallback(accessToken, user, refreshToken) {
    initializeAuth(accessToken, user, refreshToken);
    history.replace('/');
  }

  const onFinish = (e) => {
    setLoading(true);
    const { email, password } = e;
    login({
      variables: {
        email: email?.toLowerCase(),
        password
      }
    })
      .then((res) => {
        const { token, user, refreshToken } = res.data.adminLogin;
        if (successCallback) {
          successCallback(token, user, refreshToken);
        }
        history.push(ROUTES.EATERY);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="gx-login-container">
      <Spin spinning={loading} wrapperClassName="gx-login-content">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <Form
          name="Login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="gx-login-form gx-form-row0"
        >
          <Form.Item name="email" rules={[required]}>
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item name="password" rules={[required]}>
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item className="gx-text-center w-100">
            <CommonButton
              type="primary"
              style={{ width: '100%' }}
              className="w-100"
              htmlType="submit"
            >
              Login
            </CommonButton>
          </Form.Item>
          <Form.Item className="d-flex mb-0 reset-password">
            <Link to={ROUTES.RESET}>Forgot password ?</Link>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default Login;
