import { LeftOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Avatar,
  Col,
  Descriptions,
  Empty,
  Image,
  message,
  Rate,
  Row
} from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import defaultProfile from '../../../assets/images/defaultProfile.jpeg';
import { ROUTES } from '../../../common/constants';
import { btnClickHandler } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonCard from '../../../components/CommonCard';
import CommonTag from '../../../components/CommonTag';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { BLOCK_USER, UNBLOCK_USER } from '../graphql/Mutations';
import { GET_FOODIE_BY_ID } from '../graphql/Queries';

function FoodiesProfile() {
  const { id } = useParams();
  const history = useHistory();
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const [blockUser, { loading: blocking }] = useMutation(BLOCK_USER);
  const [unBlockUser, { loading: unblocking }] = useMutation(UNBLOCK_USER);
  const [visible, setVisible] = useState(false);
  const { loading, data: foodieData, error } = useQuery(GET_FOODIE_BY_ID, {
    variables: { id },
    fetchPolicy: 'network-only'
  });
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;
  const { getUserById } = foodieData;
  const handleBlockUser = () => {
    blockUser({ variables: { id: parseInt(id, 10) } })?.then(() => {
      message?.success('User blocked successfully');
      history?.push(`${ROUTES?.FOODIES}`);
    });
  };
  const onUnBlockHandler = () => {
    unBlockUser({
      variables: {
        id: parseInt(id, 10)
      }
    })?.then(() => {
      message?.success('User unblocked successfully');
      history?.goBack();
    });
  };
  return (
    <CommonCard
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <CommonButton
              onClick={() => {
                history?.goBack();
              }}
              type="default"
            >
              <LeftOutlined />
            </CommonButton>
          </Col>
          <Col>
            <h3>Details</h3>
          </Col>
          {user?.role === 'SUPER_ADMIN' &&
            (getUserById?.isBlock === true ? (
              <Col>
                <CommonButton
                  type="default"
                  onClick={onUnBlockHandler}
                  loading={unblocking}
                >
                  Unblock
                </CommonButton>
              </Col>
            ) : (
              <Col>
                <CommonButton
                  type="default"
                  onClick={handleBlockUser}
                  loading={blocking}
                >
                  Block
                </CommonButton>
              </Col>
            ))}
        </Row>
      }
    >
      <Descriptions
        className="mb-32"
        layout="vertical"
        colon={false}
        column={6}
      >
        <Descriptions.Item label="Name">
          <h4
            className="tag-pointer"
            onClick={() => {
              // eslint-disable-next-line no-undef
              window?.open(
                btnClickHandler(`foodie/${getUserById?.id}/`),
                '_blank'
              );
            }}
          >
            {getUserById?.firstName} {getUserById?.lastName}
          </h4>
        </Descriptions.Item>
        <Descriptions.Item label="City">
          <h4
            className="tag-pointer"
            onClick={() => {
              // eslint-disable-next-line no-undef
              window?.open(
                btnClickHandler(
                  `city/${getUserById?.city?.name}/${getUserById?.city?.id}/`
                ),
                '_blank'
              );
            }}
          >
            {getUserById?.city?.name}
          </h4>
        </Descriptions.Item>
        <Descriptions.Item label="Level">
          <h4>Level {getUserById?.level}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Followers">
          <h4>{getUserById?.usersFollowerCount}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Review">
          <h4>{getUserById?.userReviewCount}</h4>
        </Descriptions.Item>
        <Descriptions.Item label="Suggested Place">
          <h4>{getUserById?.usersSuggestionsCount}</h4>
        </Descriptions.Item>
      </Descriptions>
      <h3>Reviews</h3>
      <div className="grid-box row-margin">
        {getUserById?.usersReviews?.map((review) => {
          return (
            <CommonCard
              className="grid-child"
              key={review?.id}
              title={
                <>
                  <Row gutter={4} justify="space-between" align="middle">
                    <Col>
                      <Row gutter={8} align="middle">
                        <Col>
                          <Avatar src={review?.userImage || defaultProfile} />
                        </Col>
                        <Col>
                          <div className="text-wrap">
                            {review?.eatery?.name}
                          </div>
                          <div className="review-card-city">
                            {review?.eatery?.city?.name}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Rate className="rate" value={review?.rating} />
                      &nbsp;{review?.rating}
                    </Col>
                  </Row>
                </>
              }
            >
              <Row gutter={4} justify="space-between">
                <Col>
                  <h3 className="mb-8">Suggested Dish</h3>
                </Col>
                <Col>
                  <Row>
                    {review?.suggestedDishes?.map((suggestion) => {
                      return (
                        <Col key={suggestion?.id}>
                          <CommonTag label={suggestion?.name} />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <Row>
                <p className="mb-0">{review?.experience}</p>
              </Row>
            </CommonCard>
          );
        })}
      </div>
      <h3>Suggestions</h3>
      <div className="grid-box-2">
        {getUserById?.usersSuggestion?.map((suggestion) => {
          return (
            <CommonCard
              key={suggestion?.id}
              className="row-margin"
              title={
                <Row align="middle" justify="space-between">
                  <Col>
                    <h3 className="text-wrap">{suggestion?.eateryName}</h3>
                    <h5>
                      {suggestion?.eateryCity} +{suggestion?.eateryPhoneNo}
                    </h5>
                  </Col>

                  <Col>
                    <h5 className="mb-8">Famous for</h5>
                    <div className="grid-box-3">
                      {suggestion?.knownForDishes?.map((dish) => {
                        return (
                          <CommonTag
                            className="grid-child"
                            label={dish?.name}
                            key={dish?.id}
                          />
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              }
            >
              <Row align="middle" justify="center">
                {suggestion?.imageUrls?.length > 0 ? (
                  <Col>
                    <Image
                      preview={{ visible: false }}
                      className="foodie-image"
                      src={suggestion?.imageUrls?.[0]?.url}
                      key={suggestion?.imageUrls?.[0]?.id}
                      onClick={() => setVisible(true)}
                    />
                    <div className="display-none">
                      <Image.PreviewGroup
                        preview={{
                          visible,
                          onVisibleChange: (vis) => setVisible(vis)
                        }}
                      >
                        {suggestion?.imageUrls?.map((image) => {
                          return <Image src={image?.url} key={image?.id} />;
                        })}
                      </Image.PreviewGroup>
                    </div>
                  </Col>
                ) : (
                  <Empty
                    description={<span>No Image</span>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </Row>
            </CommonCard>
          );
        })}
      </div>
    </CommonCard>
  );
}

export default FoodiesProfile;
