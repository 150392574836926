import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { UPDATE_EATERY } from '../graphql/Mutations';
import { GET_EATERY_BY_ID } from '../graphql/Queries';
import EateryForm from './EateryForm';

function EditEatery() {
  const { id } = useParams();

  const [updateEatery, { loading: mutating }] = useMutation(UPDATE_EATERY);
  const { data, loading, error } = useQuery(GET_EATERY_BY_ID, {
    variables: {
      id: id
    },
    fetchPolicy: 'network-only'
  });
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <div>
      <EateryForm
        mutation={updateEatery}
        id={id}
        eateryData={data?.singleEatery}
        mutating={mutating}
      />
    </div>
  );
}

export default EditEatery;
