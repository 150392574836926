import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_ALL_DISH_CATEGORIES } from '../../dish-categories/graphql/Queries';
import { UPDATE_DISH_FOR_EATERY } from '../graphql/Mutations';
import { GET_DISH_BY_ID, GET_EATERY_BY_ID } from '../graphql/Queries';
import DishForm from './DishForm';

function EditDish({ id, cityId, actionName }) {
  const { id: eateryId } = useParams();
  const [dishData, setDishData] = useState({});
  const [editDishForEatery] = useMutation(UPDATE_DISH_FOR_EATERY, {
    refetchQueries: [
      {
        query: GET_EATERY_BY_ID,
        fetchPolicy: 'network-only',
        variables: {
          id: eateryId
        }
      }
    ]
  });
  const { data, loading } = useQuery(GET_DISH_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      id: id
    }
  });
  useEffect(() => {
    if (data?.singleDish) {
      setDishData(data?.singleDish);
    }
  }, [data]);
  const { loading: categoryLoading, data: categoryData, error } = useQuery(
    GET_ALL_DISH_CATEGORIES,
    {
      variables: { cityId }
    }
  );
  if (categoryLoading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <div>
      <DishForm
        dishData={dishData}
        mutation={editDishForEatery}
        title="Edit Dish"
        buttonLabel="Edit"
        actionName={actionName}
        dishId={id}
        dishDataLoading={loading}
        cityId={cityId}
        categoryData={categoryData}
      />
    </div>
  );
}

export default EditDish;
