import { gql } from '@apollo/client';

export const HIDE_REVIEW = gql`
  mutation removeReview($id: ID!, $removeMessage: String!) {
    removeReview(id: $id, input: { removeMessage: $removeMessage }) {
      message
    }
  }
`;

export default HIDE_REVIEW;
