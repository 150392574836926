import { gql } from '@apollo/client';

export const GET_ALL_ADMIN = gql`
  query Admins($skip: Int, $limit: Int, $sortBy: AdminSortBy) {
    Admins(filter: { sortBy: $sortBy, skip: $skip, limit: $limit }) {
      count
      admins {
        id
        name
        email
        role
        phoneNo
        status
        createdAt
      }
    }
  }
`;

export default GET_ALL_ADMIN;
