import { useQuery } from '@apollo/client';
import { Tabs, Tooltip, Typography } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  ROUTES
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_ALL_REVIEWS } from '../graphql/Queries';

function ReviewTable() {
  const history = useHistory();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const [status, setStatus] = useState('VISIBLE');
  const [sortBy, setSortBy] = useState('updatedAtDesc');
  const { data, loading, error } = useQuery(GET_ALL_REVIEWS, {
    variables: {
      skip,
      limit,
      sortBy,
      status
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: PAGINATION_DEFAULT_PAGE_SIZE,
        total: res?.reviews?.count
      };
      setPaginationProp(pagination);
    }
  });
  if (loading && !data) return <Loader />;
  if (error) return <ErrorComponent />;

  const columns = [
    {
      title: 'Foodie Name',
      dataIndex: ['foodie', 'name'],
      key: '1',
      ellipsis: {
        showTitle: false
      },
      render: (value, record) => {
        return (
          <Tooltip placement="topLeft" title={value}>
            <Typography.Text
              onClick={(e) => {
                e?.stopPropagation();
                history?.push(`${ROUTES?.FOODIES}/${record?.foodie?.id}`);
              }}
            >
              {value}
            </Typography.Text>
          </Tooltip>
        );
      },
      sorter: true
    },
    {
      title: 'Eatery Name',
      dataIndex: ['eatery', 'name'],
      key: '2',
      ellipsis: {
        showTitle: false
      },
      render: (value, record) => {
        return (
          <Tooltip placement="topLeft" title={value}>
            <Typography.Text
              onClick={(e) => {
                e?.stopPropagation();
                history?.push(`${ROUTES?.EATERY}/${record?.eatery?.id}`);
              }}
            >
              {value}
            </Typography.Text>
          </Tooltip>
        );
      },
      sorter: true
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: '3',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: '4',
      align: 'center',
      render: (createdAt) => {
        return (
          <Typography.Text>{formatDate(createdAt, 'DD/MM/YY')}</Typography.Text>
        );
      }
    }
  ];

  const onTabClick = (key) => {
    setStatus(key);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;

    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;
      setSkip(0);
      setLimit(10);

      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('foodieAsc');
              break;
            case '2':
              setSortBy('eateryNameAsc');
              break;
            case '3':
              setSortBy('ratingAsc');
              break;
            case '4':
              setSortBy('createdAtAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('foodieDesc');
              break;
            case '2':
              setSortBy('eateryNameDesc');
              break;
            case '3':
              setSortBy('ratingDesc');
              break;
            case '4':
              setSortBy('createdAtDesc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        default:
          setSortBy('updatedAtDesc');
      }
    }
    if (!_isEmpty(pagination)) {
      setSkip(skipNumber);
      setLimit(pagination?.pageSize);
      setPaginationProp({ ...paginationProp, ...pagination });
    }
  };

  return (
    <CommonCard className="tabs-card">
      <Tabs onTabClick={onTabClick} activeKey={status}>
        <Tabs.TabPane tab="Reviews" key="VISIBLE">
          <CommonTable
            rowKey={(e) => e?.id}
            columns={columns}
            data={data?.reviews?.reviews}
            paginationConfig={paginationProp}
            onChange={handleTableChange}
            onRow={(record) => {
              return {
                onClick: () => {
                  history?.push(`${ROUTES?.REVIEWS}/${record?.id}`);
                }
              };
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Hidden" key="HIDDEN">
          <CommonTable
            rowKey={(e) => e?.id}
            columns={columns}
            data={data?.reviews?.reviews}
            paginationConfig={paginationProp}
            onChange={handleTableChange}
            onRow={(record) => {
              return {
                onClick: () => {
                  history?.push(`${ROUTES?.REVIEWS}/${record?.id}`);
                }
              };
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </CommonCard>
  );
}

export default ReviewTable;
