import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ReviewDetails from './component/ReviewDetails';
import ReviewTable from './component/ReviewTable';

function Review() {
  return (
    <Switch>
      <Route path={ROUTES.REVIEW_DETAILS} component={ReviewDetails} />
      <Route path={ROUTES.REVIEW} component={ReviewTable} exact />
    </Switch>
  );
}

export default Review;
