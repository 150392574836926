import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_ALL_DISH_CATEGORIES } from '../../dish-categories/graphql/Queries';
import { CREATE_DISH_FOR_EATERY } from '../graphql/Mutations';
import { GET_EATERY_BY_ID } from '../graphql/Queries';
import DishForm from './DishForm';

function AddDish({ cityId }) {
  const { id } = useParams();

  const [createDishCategoryForEatery, { loading: mutating }] = useMutation(
    CREATE_DISH_FOR_EATERY,
    {
      refetchQueries: [
        {
          query: GET_EATERY_BY_ID,
          variables: {
            id: id
          }
        }
      ]
    }
  );
  const { loading, data, error } = useQuery(GET_ALL_DISH_CATEGORIES, {
    variables: { cityId },
    fetchPolicy: 'cache-and-network'
  });
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <div>
      <DishForm
        mutation={createDishCategoryForEatery}
        title="Add Dish"
        buttonLabel="Add Dish"
        cityId={cityId}
        mutating={mutating}
        categoryData={data}
      />
    </div>
  );
}

export default AddDish;
