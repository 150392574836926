import { useMutation } from '@apollo/client';
import React from 'react';
import { CREATE_DISH_CATEGORIES } from '../graphql/Mutations';
import CategoryForm from './CategoryForm';

function AddCategory() {
  const [createDishCategory, { loading: mutating }] = useMutation(
    CREATE_DISH_CATEGORIES
  );
  return (
    <div>
      <CategoryForm mutation={createDishCategory} mutating={mutating} />
    </div>
  );
}

export default AddCategory;
