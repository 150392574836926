import { useMutation } from '@apollo/client';
import { Form, Input, message } from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import CommonButton from '../../components/CommonButton';
import { RESET_PASSWORD, VERIFY_USER } from './graphql/Mutations';

const Verify = () => {
  const { token, id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [verifyUser] = useMutation(VERIFY_USER);
  const [resetPassword, { loading: mutating }] = useMutation(RESET_PASSWORD);

  const onFinish = (e) => {
    const { password, confirmPassword } = e;
    if (password !== confirmPassword) {
      message.error('password does not match');
      return 0;
    }
    if (location.pathname.includes('reset-password')) {
      resetPassword({
        variables: { userId: parseInt(id, 10), resetToken: token, password }
      })
        .then(() => {
          history.push('/login');
        })
        .catch(() => {});
    } else {
      verifyUser({
        variables: { id: parseInt(id, 10), token, password }
      })
        .then(() => {
          history.push('/login');
        })
        .catch(() => {});
    }
  };

  return (
    <div className="gx-login-container">
      <Form onFinish={onFinish} className="reset-password-card">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <Form.Item name="password">
          <Input.Password placeholder="Set Password" />
        </Form.Item>
        <Form.Item name="confirmPassword">
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>
        <Form.Item className="gx-text-center w-100">
          <CommonButton
            type="primary"
            style={{ width: '100%' }}
            className="w-100"
            htmlType="submit"
            loading={mutating}
          >
            Continue to login
          </CommonButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Verify;
