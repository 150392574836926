import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddEatery from './components/AddEatery';
import EateryDetails from './components/EateryDetails';
import EateryTable from './components/EateryTable';
import EditEatery from './components/EditEatery';

function Eatery() {
  return (
    <Switch>
      <Route path={ROUTES.EATERY} component={EateryTable} exact />
      <Route path={ROUTES.EDIT_EATERY} component={EditEatery} />
      <Route path={ROUTES.EATERY_ADD} component={AddEatery} />
      <Route path={ROUTES.EATERY_DETAILS} component={EateryDetails} />
    </Switch>
  );
}

export default Eatery;
