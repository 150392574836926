import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { formValidatorRules } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonInput from '../../../components/CommonInput';
import CommonModal from '../../../components/CommonModal';
import { CREATE_ADMIN } from '../../auth/graphql/Mutations';

const { required, email: emailValidation, phoneNumber } = formValidatorRules;

function AddUserModal() {
  const [createAdmin, { loading: mutating }] = useMutation(CREATE_ADMIN);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = useForm();

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };
  const onFinish = (e) => {
    const { firstName, lastName, email, phoneNo } = e;

    createAdmin({
      variables: { firstName, lastName, email: email?.toLowerCase(), phoneNo }
    })
      .then(() => {
        setModalVisible(false);
      })
      .catch(() => {});
  };
  return (
    <div>
      <CommonButton className="btn-2" type="primary" onClick={showModal}>
        <PlusOutlined /> Add User
      </CommonButton>
      <CommonModal
        button="Add user"
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={modalVisible}
        okButtonProps={{ loading: mutating }}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item label="firstName" name="firstName" rules={[required]}>
            <CommonInput />
          </Form.Item>
          <Form.Item label="lastName" name="lastName" rules={[required]}>
            <CommonInput />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[required, emailValidation]}
          >
            <CommonInput />
          </Form.Item>
          <Form.Item
            label="Phone number"
            name="phoneNo"
            rules={[required, phoneNumber]}
            className="mb-0"
          >
            <CommonInput />
          </Form.Item>
        </Form>
      </CommonModal>{' '}
    </div>
  );
}

export default AddUserModal;
