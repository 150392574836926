import { Card } from 'antd';
import React from 'react';

function CommonCard({ children, ...props }) {
  return (
    <div>
      <Card {...props}>{children}</Card>
    </div>
  );
}

export default CommonCard;
