import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(data: { email: $email, password: $password }) {
      token
      refreshToken
      user {
        id
        firstName
        lastName
        email
        phoneNo
        role
        profileImage
        message
        status
      }
    }
  }
`;

export const CREATE_ADMIN = gql`
  mutation createAdmin(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNo: String!
  ) {
    createAdmin(
      user: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phoneNo: $phoneNo
      }
    ) {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation generateResetPasswordMail($email: String!) {
    generateResetPasswordMail(email: $email, role: "ADMIN") {
      status
      message
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $userId: Int!
    $resetToken: String!
    $password: String!
  ) {
    resetPassword(
      userId: $userId
      resetToken: $resetToken
      password: $password
    ) {
      status
      message
    }
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation updatePassword($password: String!) {
    updatePassword(data: { password: $password }) {
      message
      share
      id
    }
  }
`;

export const TOKEN_VALIDATION = gql`
  mutation isValidToken($resetToken: String) {
    isValidToken(data: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;

export const EMAIL_VALIDATION = gql`
  mutation verifyUserEmail($resetToken: String) {
    verifyUserEmail(where: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyUser($id: Int!, $token: String!, $password: String!) {
    verifyUser(data: { id: $id, token: $token, password: $password }) {
      message
    }
  }
`;
export const TOKEN_FROM_REFRESH_TOKEN = gql`
  mutation tokenFromRefreshToken($refreshToken: String!) {
    tokenFromRefreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;
