import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Dropdown, Menu, Modal, Row, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { uploadImageToS3 } from '../../common/utils';
import { UPLOAD_IMAGE } from '../../modules/dish-categories/graphql/Mutations';

function ImageUpload({
  existingImageSrc,
  setImageUrl,
  multiple = false,
  imageKey
}) {
  const useOutsideImageDropdownAlerter = (ref, setMenuVisible) => {
    useEffect(() => {
      function handleClickOutside(event) {
        event?.stopPropagation();
        if (!ref?.current?.contains(event?.target)) {
          setMenuVisible(false);
        }
      }
      // eslint-disable-next-line no-undef
      document?.addEventListener('mousedown', handleClickOutside);
      return () => {
        // eslint-disable-next-line no-undef
        document?.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, setMenuVisible]);
  };

  const [previewVisible, setPreviewVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const [imageSrc, setImageSrc] = useState();
  const [generateSignedUrl] = useMutation(UPLOAD_IMAGE);
  const [menuVisible, setMenuVisible] = useState(false);
  const imageDropdownWrapper = useRef(null);
  useOutsideImageDropdownAlerter(imageDropdownWrapper, setMenuVisible);

  useEffect(() => {
    if (existingImageSrc && !multiple) {
      setImageSrc(existingImageSrc);
    }
  }, [existingImageSrc, multiple]);
  const handleUpload = async () => {
    if (!multiple) {
      try {
        const res = await generateSignedUrl({
          variables: {
            fileName: currentImage.name,
            fileType: currentImage.type,
            key: imageKey
          }
        });
        setImageUrl(res?.data?.generateSignedUrl?.fileUrl);
        uploadImageToS3(currentImage, res?.data?.generateSignedUrl?.signedUrl);
      } catch (error) {
        return error;
      }
    } else {
      const urls = [];
      currentImage?.forEach(async (image) => {
        try {
          const response = await generateSignedUrl({
            variables: {
              fileName: image?.name,
              fileType: image?.type
            }
          });
          uploadImageToS3(image, response?.data?.generateSignedUrl?.signedUrl);
          urls.push(response?.data?.generateSignedUrl?.fileUrl);
        } catch (error) {
          return error;
        }
      });
      setImageUrl(urls);
    }
  };
  const handlePreview = async (e) => {
    e?.stopPropagation();
    setPreviewVisible(true);
    setMenuVisible(!menuVisible);
  };

  const removeImgHandler = (e) => {
    e?.stopPropagation();
    setImageSrc('');
    setCurrentImage('');
    setMenuVisible(!menuVisible);
  };

  const props = {
    accept: '.png,.jpg,.jpeg',
    multiple: multiple,
    showUploadList: multiple,
    listType: multiple ? 'picture-card' : 'text',
    headers: {
      authorization: 'authorization-text'
    },
    onChange: handleUpload,
    beforeUpload: (file, fileList) => {
      if (!multiple) {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          setImageSrc(reader.result);
        };
      }
      setCurrentImage(multiple ? fileList : file);
    }
  };

  const UploadTrigger = () => {
    const preview = (
      <img src={imageSrc} alt="eatery" className="full-width upload-trigger" />
    );
    const uploader = (
      <div>
        <PlusOutlined />
      </div>
    );

    return (
      <div className={multiple ? ' ' : 'upload-trigger'}>
        {imageSrc && !multiple ? preview : uploader}
      </div>
    );
  };

  const menu = (
    <div onClick={(e) => e?.stopPropagation()} ref={imageDropdownWrapper}>
      <Menu>
        <Menu.Item key="upload">
          <Upload
            onClick={(e) => e?.stopPropagation()}
            {...props}
            className="upload-img-cmp"
          >
            <span>Edit</span>
          </Upload>
        </Menu.Item>
        <Menu.Item key="delete">
          <span onClick={removeImgHandler}>Delete</span>
        </Menu.Item>
        <Menu.Item key="preview">
          <span onClick={handlePreview}>View</span>
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <>
      <Row justify="center">
        <Upload {...props} onPreview={handlePreview} className="upload-img-cmp">
          <UploadTrigger />
          <div>
            <Dropdown
              visible={menuVisible}
              overlay={menu}
              trigger={['click']}
              onClick={(e) => {
                e?.stopPropagation();
              }}
            >
              <EllipsisOutlined
                className={`img-options ${
                  !imageSrc ? 'img-options-hidden' : ''
                } `}
                onClick={(e) => {
                  e?.stopPropagation();
                  setMenuVisible(true);
                }}
              />
            </Dropdown>
          </div>
        </Upload>
      </Row>
      <Modal
        visible={previewVisible}
        title="Preview"
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="Eatery" className="image-preview" src={imageSrc} />
      </Modal>
    </>
  );
}

export default ImageUpload;
