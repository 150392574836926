import { useMutation } from '@apollo/client';
import { Col, Popover, Row } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import CommonButton from '../../../components/CommonButton';
import { DELETE_CATEGORY } from '../graphql/Mutations';
import { GET_ALL_DISH_CATEGORIES } from '../graphql/Queries';

function DeleteCategory({ id }) {
  const { id: cityId } = useParams();
  const [visible, setVisible] = useState(false);
  const [deletecategory, { loading }] = useMutation(DELETE_CATEGORY, {
    refetchQueries: [{ query: GET_ALL_DISH_CATEGORIES, variables: { cityId } }]
  });
  const handleDelete = () => {
    deletecategory({
      variables: {
        id: id
      }
    }).catch((error) => {
      return error;
    });
  };
  return (
    <div>
      <Popover
        visible={visible}
        onClick={(e) => {
          e?.stopPropagation();
        }}
        trigger="click"
        content={
          <>
            <p>Are you sure you want to delete this category</p>
            <Row>
              <Col>
                <CommonButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    handleDelete();
                  }}
                  loading={loading}
                >
                  yes
                </CommonButton>
              </Col>
              &nbsp;
              <Col>
                <CommonButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    setVisible(false);
                  }}
                >
                  No
                </CommonButton>
              </Col>
            </Row>
          </>
        }
      >
        <p
          onClick={(e) => {
            setVisible(true);
            e?.stopPropagation();
          }}
          className="gx-text-center pointer"
        >
          Delete
        </p>
      </Popover>
    </div>
  );
}

export default DeleteCategory;
