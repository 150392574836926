import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Popover, Space } from 'antd';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';

const UserProfile = ({ history }) => {
  const { getCurrentUser } = useContext(AppContext);
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  const user = getCurrentUser() || {};

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          history.push(ROUTES.CHANGE);
        }}
      >
        Change Password
      </li>
      <li
        onClick={() => {
          history.push(ROUTES.LOGOUT);
        }}
      >
        Logout
      </li>
    </ul>
  );

  return (
    <div className="gx-avatar-row">
      <Popover
        className="d-flex flex-row align-items-center"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <Space direction="horizontal">
          <Avatar
            className="gx-size-35 gx-pointer mr-2"
            alt="Avatar"
            icon={<UserOutlined />}
          />

          <span className="gx-avatar-name d-flex flex-row h4 font-semibold align-items-center align-self-center mr-2">
            {`${user?.firstName} ${user?.lastName}`}
            <DownOutlined className="gx-fs-11 align-self-center ml-6 mt-2" />
          </span>
        </Space>
      </Popover>
    </div>
  );
};

export default withRouter(UserProfile);
