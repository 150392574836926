import { useQuery } from '@apollo/client';
import { Col, Row, Tooltip, Typography } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { PAGINATION_DEFAULT_PAGE_SIZE } from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import ErrorComponent from '../../../ErrorComponent';
import Loader from '../../../Loader';
import { GET_ALL_ADMIN } from '../graphql/Queries';
import AddUserModal from './AddUserModal';

function AdminUserTable() {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState('updatedAtDesc');
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const { data, loading, error } = useQuery(GET_ALL_ADMIN, {
    variables: { skip, limit, sortBy },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: PAGINATION_DEFAULT_PAGE_SIZE,
        total: res?.Admins?.count
      };
      setPaginationProp(pagination);
    }
  });
  if (loading && !data) return <Loader />;
  if (error) return <ErrorComponent />;

  const columns = [
    {
      title: 'Admin Name',
      dataIndex: 'name',
      key: '1',
      sorter: true,
      fixed: 'left',
      className: 'font-semibold',
      ellipsis: {
        showTitle: false
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: '2',
      width: '30%',
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      )
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNo',
      key: '3'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: '4'
    },

    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: '5',
      sorter: true,
      render: (createdAt) => {
        return (
          <Typography.Text>{formatDate(createdAt, 'DD/MM/YY')}</Typography.Text>
        );
      }
    }
  ];

  const handleTableChange = (pagination, _, sorter) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;
    if (!_isEmpty(sorter)) {
      const { order, columnKey } = sorter;
      setSkip(0);
      setLimit(10);

      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case '1':
              setSortBy('nameAsc');
              break;
            case '2':
              setSortBy('emailAsc');
              break;
            case '5':
              setSortBy('createdAtAsc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case '1':
              setSortBy('nameDesc');
              break;
            case '2':
              setSortBy('emailDesc');
              break;
            case '5':
              setSortBy('createdAtDesc');
              break;
            default:
              setSortBy('updatedAtDesc');
              break;
          }
          break;
        default:
          setSortBy('updatedAtDesc');
      }
    }
    if (!_isEmpty(pagination)) {
      setSkip(skipNumber);
      setLimit(pagination?.pageSize);
      setPaginationProp({ ...paginationProp, ...pagination });
    }
  };
  return (
    <CommonCard
      className="table-card"
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <h3>Admin Users</h3>
          </Col>
          <Col>
            <AddUserModal />
          </Col>
        </Row>
      }
    >
      <CommonTable
        rowKey={(e) => e?.id}
        columns={columns}
        data={data?.Admins?.admins}
        paginationConfig={paginationProp}
        onChange={handleTableChange}
        tableRowIsClickable={false}
      />
    </CommonCard>
  );
}

export default AdminUserTable;
