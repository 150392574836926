import { useMutation } from '@apollo/client';
import { Col, Popover, Row } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import { DELETE_DISH_FOR_EATERY } from '../graphql/Mutations';
import { GET_EATERY_BY_ID } from '../graphql/Queries';

function DeleteDish({ id }) {
  const history = useHistory();
  const { id: eateryId } = useParams();
  const [deleteDishforEatery, { loading: mutating }] = useMutation(
    DELETE_DISH_FOR_EATERY,
    {
      refetchQueries: [{ query: GET_EATERY_BY_ID, variables: { id: eateryId } }]
    }
  );
  const [visible, setVisible] = useState(false);
  const deleteDish = () => {
    deleteDishforEatery({
      variables: { id }
    })?.then(() => {
      history?.push(`${ROUTES?.EATERY}/edit/${eateryId}`);
    });
  };
  return (
    <div>
      <Popover
        visible={visible}
        onClick={(e) => {
          e?.stopPropagation();
        }}
        trigger="click"
        content={
          <>
            <p>Are you sure you want to delete this eatery</p>
            <Row>
              <Col>
                <CommonButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    deleteDish();
                  }}
                  loading={mutating}
                >
                  yes
                </CommonButton>
              </Col>
              &nbsp;
              <Col>
                <CommonButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    setVisible(false);
                  }}
                >
                  No
                </CommonButton>
              </Col>
            </Row>
          </>
        }
      >
        <p
          className="gx-text-center pointer"
          onClick={(e) => {
            setVisible(true);
            e?.stopPropagation();
          }}
        >
          Delete
        </p>
      </Popover>
    </div>
  );
}

export default DeleteDish;
